.dc-completed {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1000; // Higher than tooltip's 999
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background: rgba($dc-navy, 0.95);
  border-radius: $dc-border-radius;
}

.dc-completed__status {
  display: flex;
  align-items: center;
  justify-content: center;

  .progress-tooltip,
  .progress-tooltip.inbetween {
    border: 0;
    font-size: dc-font-size-h5;
    font-weight: $dc-font-weight-regular;
    color: $dc-green;

    span {
      color: $dc-green;
    }
  }
}

.dc-completed__icon {
  margin-right: spacing(1);
}

.dc-completed__body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  max-width: 500px;
  color: $dc-white;
  text-align: center;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: 100%;
  }
}

.dc-completed__message {
  font-weight: $dc-font-weight-regular;
  letter-spacing: 0.4px;
  max-width: 100%; /* IE11 */
  padding: spacing(3) spacing(4);

  code {
    background-color: rgba($dc-white, 0.1);
    color: $dc-white;
  }
}

.dc-completed__instructions {
  margin: 0;
  font-weight: $dc-font-weight-regular;
  text-transform: uppercase;
}

/**
 * Shortcuts
 */

.dc-shortcuts {
  align-self: stretch;
  flex-shrink: 0;
  padding: spacing(4);
  position: relative;
  color: $dc-white;
  background: $dc-navy;
  text-align: center;
}

.dc-shortcuts__title {
  margin: 0 0 spacing(6);
  color: $dc-white;
}

.dc-shortcuts__close {
  position: absolute;
  top: 0;
  right: 0;
  color: $dc-white;
  transform: translate(-67%, 0%);
  font-size: 1.3rem;
}

.dc-shortcuts__key {
  background: $dc-navy-light;
  border-radius: 4px;
  color: $dc-white;
  padding: 12px;
}

.dc-shortcuts__key--sm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 27px;
  padding: 0;
  box-shadow: 0 2px 0 $dc-navy;
}

/**
 * IE 11 needs alignment for absolute items in a flex container
 */

.shortcuts-already-used {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  text-align: center;
}

/* TODO: Dan */
/* Everything below this line should be refactored */

.sidebar-overlay--content {
  max-height: 100%;
  max-width: 100%;
  color: $dc-white;
  margin: auto;

  h4 {
    font-weight: $dc-font-weight-bold;
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
    font-weight: $dc-font-weight-light;
    line-height: 1.8;
    padding: 10px 0;

    code {
      line-height: 1.2;
    }

    pre {
      text-align: left;

      code {
        background-color: inherit;
        color: $dc-navy-text;
      }
    }
  }

  .warning {
    padding: 0px 0px 15px 0px;
    color: $dc-red;
    font-weight: 0.8em;
  }

  .success {
    padding: 0px 0px 15px 0px;
    color: $dc-green;
    font-weight: 0.8em;
  }

  .progress-tooltip {
    &,
    &.completed,
    &.inbetween {
      vertical-align: middle;
      color: $dc-white;
      background-color: $dc-blue;
      text-align: center;
      width: auto;
      line-height: 1;
      padding: 4px 10px;
      border: none;
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 4px;
    }
  }

  a code {
    color: $dc-blue;
  }

  code {
    background-color: $dc-yellow-text;
    color: $dc-white;
  }
}

.congrats-wrapper {
  width: 100%;
  margin: 0 0 spacing(6) 0;
  font-weight: $dc-font-weight-regular;

  .congrats-title {
    margin: 0px;
    padding: 0px;
  }

  .congrats-subtitle {
    margin: 0px;
    padding: 0px;
  }

  .fa {
    font-size: dc-font-size-h5;
    vertical-align: bottom;
  }
}

.exercise--assignment {
  padding: spacing(4);
  position: relative;

  p img {
    max-width: 100%;
  }
}

.exercise--sidebar .sidebar-overlay {
  flex-direction: column;
  justify-content: center;

  .sidebar-overlay--content {
    margin: 0;
  }
}
