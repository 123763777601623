.modal.modal-window {
  z-index: 1050;
  display: block;
}

.modal-backdrop {
  z-index: 1040;
}

.modal.modal-window.in.modal-dialog {
  overflow: visible;
}

.global-spinner {
  text-align: center;
}

.solution-markdown-title {
  font-style: italic;
}

.navbar--title .logo-title {
  font-size: 1.125rem;
  font-weight: $dc-font-weight-bold;
  color: $dc-navy-text;
  margin: 0 0 0 5px;
  display: block;
  float: left;
  line-height: 40px;
}

.slides-modal {
  height: 600px;
}

.navigation--buttons-center {
  margin-left: -84px;
  margin-top: -15px;
  transform: none;
}

.keyboard-shortcuts-panel {
  position: relative;
  padding: 15px 15px 30px;
  h4 {
    text-align: center;
    margin-bottom: 20px;
  }
  .keyboard-shortcuts-panel__close {
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    border: none;
    transform: translate(-70%);
    font-size: 1.5rem;
  }
  .keyboard-shortcuts-panel__key {
    text-align: right;
    span {
      border: 1px solid #46899c;
      border-radius: 7px;
      padding: 5px 12px;
      background-color: #3caccb;
      color: white;
    }
  }
  table {
    tr {
      background-color: inherit;
    }
    tr:nth-of-type(2n + 1) {
      background-color: #ecf4f7;
    }
  }
}

.flex-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.console-sql-container {
  table {
    margin-bottom: 0;
    white-space: pre;
  }
  .sql-query-message {
    flex: 1;
    font-size: 0.9rem;
    font-family: monospace;
    margin: 15px;
    overflow: auto;

    pre {
      background: none;
      padding: 0;
      border: none;
    }
  }
}

.sql-query-result-error {
  overflow: auto;
  position: relative;
  max-height: 35%;
  .sql-query-result-error__wrapper {
    background-color: $dc-grey-light;
    transition: transform 0.5s ease-out,
      max-height 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    transform: translateY(0%);
    max-height: 400px;
    min-height: 0;
    &.sql-query-result-error__shrink {
      transform: translateY(100%);
      max-height: 0;
    }
    .sql-query-result-error__close {
      font-size: $dc-font-size-h4;
      position: absolute;
      top: -2px;
      border: none;
      right: 8px;
    }
    .sql-query-result-error__info {
      color: $dc-red-text;
      background-color: $dc-grey-light;
      padding: 10px 15px 10px 15px;
      font-family: monospace;
      font-size: 0.9rem;
      white-space: pre-wrap;
    }
  }
}

.theme:not(.theme--light) {
  .sql-query-result-error__info {
    color: $dc-red-dark;
    background-color: $dc-navy-dark;
  }
  .sql-query-toolbar {
    background-color: $dc-navy-dark;
    color: $dc-white;
  }
  .sql-query-result-error__close {
    color: $dc-white;
  }
}

.sql-query-toolbar {
  font-size: 0.9rem;
  font-weight: $dc-font-weight-regular;
  background-color: $dc-grey-light;
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 6px 0px;
  .sql-query-toolbar__rows {
    flex: 1;
    margin: auto 15px;
  }
}

.console--wrapper {
  height: 100%;
}

.exam-exercise--instructions .dc-btn-continue {
  margin: 0;
}
// React CSS Animation

.hcEaseOut {
  position: relative;
  transition: top 500ms ease-in;
}

.hcEaseOut-appear {
  transform: translateY(-300px);
  position: relative;
}

.hcEaseOut-appear.hcEaseOut-appear-active {
  transform: translateY(0px);
  transition: transform 500ms ease-out;
}

.hcStamp-appear.hcStamp-appear-active {
  transform: scale(1);
  transition: all 0.5s cubic-bezier(0.54, -1.5, 0.1, 2.46) !important;
}
.hcStamp-appear {
  transform: scale(3);
}

.hcSlide-wrapper {
  display: inline-flex;
}

.hcSlide-leave {
  transition: all 0.3s ease-out;
}

.hcSlide-leave.hcSlide-leave-active {
  transform: translateX(-30%);
  opacity: 0.1;
}

.hcSlide-enter.hcSlide-enter-active {
  transform: translateX(0%);
  transition: all 0.5s ease-out;
  opacity: 1;
}
.hcSlide-enter {
  opacity: 0.1;
  transform: translateX(10%);
}

.hcStampThenFade-leave.hcStampThenFade-leave-active {
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.11, 2.29);
  transform: scale(0.1);
  opacity: 0.1;
}

.hcStampThenFade-enter.hcStampThenFade-enter-active {
  transform: scale(1);
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.11, 2.29);
  opacity: 1;
}
.hcStampThenFade-enter {
  opacity: 0.1;
  transform: scale(0);
}

.xterm-terminal {
  height: 100%;
  background: #000000;
  padding-left: 5px;
}

// ----- Style guide overrides ----- //

.dc-input-radio__text {
  color: $dc-navy-text;
}
