.modal-body.no-padding {
  padding: 0;
}

a.modal--close {
  position: absolute;
  color: $dc-white;
  right: 12px;
  top: 12px;
  z-index: 1041;
  border-bottom: none;

  i.fa {
    font-size: 2.2rem;
    font-weight: 900;
    line-height: 30px;
  }

  &:hover,
  &:focus {
    color: $dc-grey-light;
    border-bottom: none;
  }
}

.modal-dialog__close {
  background-color: $dc-blue;
  border-radius: 50%;
  border: none;
  color: $dc-white;
  font-size: 0;
  height: 42px;
  position: absolute;
  right: -20px;
  top: -20px;
  width: 42px;
  z-index: 2;
}

#no-access-modal {
  h5 {
    margin-bottom: 10px;
  }

  .logos {
    margin: 12px 0;
  }
}

.iframe-modal {
  border: none;
}

.modal-content {
  border: none;
  box-shadow: none;

  .congrats-img {
    margin-top: 2rem / 2;
    margin-bottom: 2rem;
    width: 160px;
    height: 160px;
  }

  iframe {
    border: none;
  }
}

.modal {
  z-index: 1071;
}

.screen-overlay {
  z-index: 1000;
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $dc-white;

  iframe {
    border: none;
  }
}

// TODO: Make sure modals have the highest z-index
.modal-overlay {
  z-index: 10000 !important;
}
