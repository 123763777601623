.vh-align {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
}

.h-align {
  position: absolute;
  left: 50%;
  display: block;
  transform: translateX(-50%);
}

.v-align {
  position: absolute;
  top: 50%;
  display: block;
  transform: translateY(-50%);
}

// TODO: replace ajax-success and ajax-fail with flash banners
.ajax-success,
.ajax-fail {
  display: none;
  float: right;
  margin: 0;
}

.ajax-success {
  color: $dc-navy-text;
}

.ajax-fail {
  color: $dc-red;
}

.global-spinner {
  @extend .vh-align;
  z-index: 1;
  width: 10rem;
}
