.modal--exercise__current {
  background: $dc-grey-light;
}

// TODO: Dan Refactor

.course-outline__list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.course-outline__progress {
  display: flex;
  align-items: center;
}

.course-outline__percentage {
  color: $dc-navy-text;
  font-weight: $dc-font-weight-regular;
  margin-right: spacing(2);
}

.course-outline__progress-bar {
  display: block;
  background: $dc-grey-light;
  overflow: hidden;
  border-radius: $bar-border-radius;
  width: 100%;
}

.course-outline__progress-fill {
  width: 0;
  transition: width 0.5s;
  height: 8px;
  background: $dc-navy;
  border-radius: $bar-border-radius;
  display: block;
  border: none;
}

.course-outline__exercise {
  padding: spacing(2) spacing(4);
  margin: 0 spacing(-4);

  &:first-child {
    margin-top: spacing(2);
  }

  &:last-child {
    margin-bottom: spacing(2);
  }
}

// TODO: Dan
// All items below here should be renamed / refactored

.modal--chapter {
  margin-bottom: 40px;
  &:first-child {
    margin-top: 30px;
  }

  border: 1px solid $dc-grey-light;
  border-radius: 2px;
}

.modal__card {
  text-align: left;
  padding: 20px;
  position: relative;

  .chapter-progress-bar {
    margin-top: 11px;
    position: relative;
    height: 24px;

    span {
      position: absolute;
      color: $dc-navy-text;
    }

    .progress-bar {
      margin-bottom: 0;
      position: absolute;
      left: 40px;
      right: 0;
      width: auto;
      top: 2px;
      background-color: $dc-white;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.outline--card-end {
  background-color: $dc-grey-light;
  padding: 8px;

  .expand-link {
    margin: 12px 0px 12px 20px;
  }
}

.modal--chapter-title {
  display: block;
  margin-bottom: 20px;
  padding-right: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .chapter-number {
    color: $dc-navy-text;
    border-radius: 50%;
    border: 3px solid $dc-blue;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1;
    margin-right: 10px;
    vertical-align: bottom;
  }

  .label {
    position: absolute;
    top: 12px;
    right: 0px;
  }
}

.modal--exercises {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  display: none;

  .modal--exercise-link {
    color: rgb(121, 120, 120);
    display: flex;
    position: relative;

    .progress-tooltip {
      font-size: $dc-font-size-base;
      font-weight: $dc-font-weight-regular;
      border-radius: 0;
      border: 0;
      color: $dc-navy-text;
      width: auto;
      text-align: right;
      line-height: inherit;

      .fa {
        margin-right: 3px;
      }
    }
  }
  .exercise--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background-color: $dc-navy;
    color: $dc-white;
    font-size: $dc-font-size-micro;
  }

  .modal--exercise-title {
    color: $dc-navy-text;
    font-weight: $dc-font-weight-regular;
    margin-left: spacing(3);
  }

  .modal--exercise__completed {
    .modal--exercise-title,
    .progress-tooltip {
      color: $dc-navy-text;
    }

    .exercise--icon {
      background-color: rgba($dc-navy, 0.3);
    }
  }

  .progress-tooltip {
    @extend .v-align;
    right: 0;
  }
}

.modal--chapter__expanded {
  .modal--exercises {
    display: block;
  }
}

.dc-chapter__number {
  background-color: $dc-navy;
}
