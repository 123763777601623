.instructions--bullet {
  position: relative;

  .active-tab-info {
    display: flex;
    align-items: center;

    /**
    * This is a temporary hack until the tab component is refactored
    * Blame Dan
    */

    position: absolute;
    top: -26px;
    right: 0;
    left: auto;
    z-index: 1;
  }
}

/* TODO: Dan */
/* Everything below this line should be refactored */

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin animation-blink($name, $start, $end) {
  @include keyframes($name) {
    0% {
      background: $start;
    }

    50% {
      background: $end;
    }

    100% {
      background: $start;
    }
  }
}

.bullet-instruction {
  border-bottom: 1px solid $dc-white;
  background: $dc-grey-light;
  position: relative;

  &:last-child {
    border: none;
  }

  .subexercise-tab {
    position: absolute;
    left: spacing(3);
    top: 17px;
  }

  .exercise--instructions {
    margin-bottom: 0;
  }
}

.tab-icon {
  position: relative;
  top: 1px;
}

.subexercise-tab a {
  align-items: center;
  background: $dc-white;
  border-radius: 50%;
  color: $dc-navy-text;
  display: flex;
  font-size: $dc-font-size-micro;
  font-weight: $dc-font-weight-regular;
  height: 20px;
  justify-content: center;
  position: relative;
  transition: all 250ms;
  width: 20px;

  span {
    top: 1px;
    position: relative;
  }

  &.active-tab {
    background: $dc-navy;
    color: $dc-white;
  }

  &.completed {
    background: $dc-green; // Not an official color
    color: $dc-navy-text;

    &.active-tab {
      background: $dc-green;
    }
  }

  &.disabled {
    background: $dc-white;
    color: $dc-navy-text;
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    transform: scale(1.1);
  }

  &.active-tab.animation-blink:not(.completed) {
    @include animation-blink(animation-blink, #33aacc, #7ecce2);
    animation: animation-blink 1.6s infinite;
  }
}

.instructions--tab {
  position: relative;

  .active-tab-info {
    position: static;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $dc-white;

    .skills-tooltip {
      margin-top: 0px;
    }
  }

  .exercise--instructions {
    border: none;
    margin: 0;

    .exercise--instructions__content,
    .exercise--multiple-choice {
      margin: 15px;
    }
  }

  .exercise--buttons {
    position: absolute;
    right: 0px;
    bottom: 0px;
    transform: translateY(200%);
    z-index: 1;
  }
}

.bullet-steps {
  .exercise--instructions {
    background: transparent;
    border: none;
    overflow: visible;
  }

  .exercise--instructions__content > p {
    margin: 0;
  }

  ul {
    padding: 0;
    margin: 0;
  }
}

.bullet-steps .bullet-instructions-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;

  .clickable-tab {
    cursor: pointer;
  }

  .active--instruction {
    background: $dc-white;
  }

  .bullet-instruction:not(.active--instruction) {
    .bullet-instructions-list__instruction-content p,
    code {
      color: $dc-navy-text;
      opacity: 0.5;
    }
  }
}

.bullet-instructions-list__instruction-content {
  .exercise--instructions__content {
    margin: 0 !important;
    padding-left: spacing(6);
  }
}
