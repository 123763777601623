.slides-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  object {
    width: 100%;
    height: 100%;
  }
}

.slides-modal-wrapper {
  position: relative;
  width: 100%;
  height: 0;

  object {
    width: 100%;
  }
}

#fake {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: none;
}