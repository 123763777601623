.exercise-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  height: 32px;
  width: 100%;
  padding: 0 spacing(4);
}

.dc-progress-indicator {
  justify-content: center;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dc-progress-indicator__item {
    width: 100px;
  }
}
