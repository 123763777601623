// Copied from Bootstrap
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: $dc-bp-sm) {
  .container {
    width: 750px;
  }
}
@media (min-width: $dc-bp-md) {
  .container {
    width: 970px;
  }
}
@media (min-width: $dc-bp-lg) {
  .container {
    width: 1170px;
  }
}

.clearfix:before,
.clearfix:after,
.container:before,
.container:after {
  content: ' ';
  display: table;
}
.clearfix:after,
.container:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
