.listview__outer {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.listview__inner {
  height: 100%;
  overflow-y: auto;
}

.listview__header {
  height: 32px; // for now, this should match the height given in the component props
  background-color: $dc-grey-medium;
  width: 100%;
  z-index: 2;
}

.listview__content {
  padding-bottom: 1px; // Avoid to have wrong calculations with overflowing margins from the content.
  img {
    width: '-webkit-fill-available';
    width: -moz-available;
    width: fill-available;
  }
  label {
    user-select: text;
  }
}

.listview__section {
  width: 100%; // to prevent overflow affecting onboarding calculations
}
