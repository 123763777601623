$right-sidebar-width: 25%;
$trigger-width: 48px;
#rendered-view {
  transition: width 0.5s;
}

.uservoice-link {
  background-color: white;
  position: absolute;
  height: 40px;
  bottom: 0; left: 0; right: 0;
  padding: 0 15px;
  p {
    line-height: 40px;
  }
}