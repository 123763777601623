// Fieldset

.dc-fieldset {
  border: 0;
  padding: 12px 0;
  width: 100%;
}

@media screen and (min-width: 480px) {
  // TODO: Find why this is used and refactor
  .dc-fieldset {
    width: 20em;
  }
}

// Labels

.dc-label {
  align-items: center;
  color: $dc-navy-text;
  display: flex;
  font-size: $dc-font-size-base;
  font-weight: $dc-font-weight-bold;
  line-height: $dc-line-height-base;
  margin-bottom: 12px;
}

.dc-label__number {
  background-color: $dc-green;
  border-radius: 50%;
  display: inline-block;
  font-size: $dc-font-size-small;
  line-height: 1;
  margin-right: 8px;
  padding: 5px 0; // 14px lh + 5px v-padding = 24px height
  text-align: center;
  width: 24px;
}

// Text type inputs

.dc-input--text,
.dc-textarea {
  background-color: $dc-white;
  border: 2px solid $dc-beige-400;
  border-radius: $dc-border-radius;
  color: $dc-navy-text;
  font-size: $dc-font-size-base;
  line-height: $dc-line-height-base;
  padding: 11px 15px; // +1px border
  transition: border $dc-transition;
  width: 100%;

  &::placeholder {
    color: rgba($dc-navy-text, 0.6);
    transition: color $dc-duration;
  }

  &:focus,
  &.focus {
    border-color: $dc-blue-dark;
    outline: none;
  }

  &:disabled,
  &.dc-is-disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

@media all and (-ms-high-contrast: none) {
  .dc-input--text {
    height: 48px;
  }
}

// Date input

.dc-input--date {
  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    color: $dc-navy-text;
  }
}

// Combo input

.dc-input-combo {
  position: relative;
}

.dc-input-combo__cap {
  bottom: 1px;
  position: absolute;
  top: 0;
  width: 56px;
  z-index: 10;
}

.dc-input-combo--start {
  .dc-input-combo__cap {
    border-radius: $dc-border-radius 0 0 $dc-border-radius;
    left: 0;
  }

  .dc-input-combo__input {
    padding-left: 56px; // Accommodates input padding and cap width
  }
}

.dc-input-combo--end {
  .dc-input-combo__cap {
    border-radius: 0 $dc-border-radius $dc-border-radius 0;
    right: 0;
  }

  .dc-input-combo__input {
    padding-right: 56px; // Accommodates input padding and cap width
  }
}

// TODO: Find why this is used and refactor

/* stylelint-disable */

// Tabs
.tabs {
  background-color: $dc-navy;
  border-radius: $dc-border-radius;
  display: flex;
  overflow: none;
}

.tabs input[type='radio'] + label {
  border-radius: $dc-border-radius;
  color: $dc-white;
  display: inline-block;
  font-size: $dc-font-size-small;
  padding: 12px 0;
  text-align: center;
  text-transform: uppercase;
  transition: background-color $dc-transition, color $dc-transition;
  vertical-align: top;
  width: 33%;
}

.tabs.two input[type='radio'] + label {
  width: 50%;
}

.tabs.three input[type='radio'] + label {
  width: 33%;
}

.tabs.four input[type='radio'] + label {
  width: 24%;
}

.tabs.five input[type='radio'] + label {
  width: 20%;
}

.tabs input[type='radio'] + label::after,
.tabs input[type='radio'] + label::before {
  display: none;
}

.tabs input[type='radio']:checked + label {
  background-color: $dc-white;
  color: $dc-navy-text;
  font-weight: $dc-font-weight-bold;
}

/* stylelint-enable */

// TODO: Move to marketing or refactor for all apps

.form-error {
  color: $dc-red;
  display: none;
  font-size: $dc-font-size-small;
}

.form-error-active {
  display: block !important;
}

.form-required {
  box-shadow: 0 0 2px 1px $dc-red;
}

.dc-select-container {
  position: relative;

  &::after {
    background-color: $dc-white;
    border-left: 1px solid $dc-white;
    border-radius: 0 $dc-border-radius $dc-border-radius 0;
    content: '';
    height: 44px; // +2px border-top +2px border-bottom
    pointer-events: none;
    position: absolute;
    right: 2px;
    top: 2px;
    width: 46px; // +2px border-left
  }

  &::before {
    background: url('https://styleguide-assets.datacamp.com/images/marketing/components/icons/arrow_down-19bbe4876d.svg')
      no-repeat 50% 50% / 16px 16px;
    content: '';
    height: 16px;
    pointer-events: none;
    position: absolute;
    right: 18px; // -2px border on dc-select
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    z-index: 10; // sit above ::after
  }
}

.dc-select {
  appearance: none;
  background-color: $dc-white;
  background-image: none;
  border: 2px solid $dc-beige-400;
  border-radius: $dc-border-radius;
  box-shadow: none;
  box-sizing: border-box;
  color: $dc-navy-text;
  cursor: pointer;
  height: 48px; // firefox, IE11...
  line-height: $dc-line-height-base;
  padding: 11px 15px; // +1px border
  transition: background $dc-transition, border-color $dc-transition,
    color $dc-transition;
  width: 100%;

  &:focus,
  &.focus {
    border-color: $dc-blue-dark;
    outline: none;
  }

  &:disabled,
  &.dc-is-disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  ::-ms-expand {
    display: none;
  }
}

/** checkbox */

.dc-input-checkbox {
  display: inline-flex;
  line-height: 16px;
  position: relative;
}

/** input */

.dc-input-checkbox__input {
  display: block;
  height: 16px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 16px;
  z-index: -1;
}

/** indicator */

.dc-input-checkbox__indicator {
  align-items: center;
  background-color: $dc-white;
  border: 2px solid $dc-beige-400;
  border-radius: $dc-border-radius;
  display: flex;
  flex-shrink: 0;
  height: 16px;
  justify-content: center;
  position: relative;
  transition: border-color $dc-transition, box-shadow $dc-transition;
  width: 16px;

  // has error
  .dc-has-error & {
    border-color: $dc-red;
  }

  // disabled
  .dc-input-checkbox__input:disabled ~ & {
    opacity: 0.3;
    cursor: not-allowed;
  }

  // checked
  .dc-input-checkbox__input:checked ~ & {
    border-color: $dc-blue;
  }

  // focus
  .dc-input-checkbox__input:focus ~ & {
    border-color: $dc-blue-dark;
  }

  // focus and checked
  .dc-input-checkbox__input:focus:checked ~ & {
    border-color: $dc-blue-dark;
  }

  // has error + checked
  .dc-has-error .dc-input-checkbox__input:checked ~ & {
    border-color: $dc-red;
  }

  // has error + focused
  .dc-has-error .dc-input-checkbox__input:focus ~ & {
    border-color: $dc-red-dark;
  }

  // has error + focus and checked
  .dc-has-error .dc-input-checkbox__input:focus:checked ~ & {
    border-color: $dc-red;
  }
}

/** text */

.dc-input-checkbox__text {
  color: $dc-navy-text;
  flex-grow: 1;
  font-weight: $dc-font-weight-regular;
  line-height: $dc-line-height-base;
  margin-left: 8px;
  position: relative;
  top: -4px;
  transition: color $dc-transition;

  // disabled
  .dc-input-checkbox__input:disabled ~ & {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

/** check */

.dc-input-checkbox__check {
  height: 8px;
  opacity: 0;
  top: 0 !important;
  transition: opacity $dc-transition;
  width: 8px;

  // checked
  .dc-input-checkbox__input:checked ~ .dc-input-checkbox__indicator & {
    fill: $dc-blue;
    opacity: 1;
  }

  // disabled and checked
  .dc-input-checkbox__input:disabled:checked ~ .dc-input-checkbox__indicator & {
    opacity: 0.3;
  }

  // has error + checked
  .dc-has-error
  .dc-input-checkbox__input:checked
  ~ .dc-input-checkbox__indicator
  & {
    fill: $dc-red;
    opacity: 1;
  }
}

/** Flex Modifer */

.dc-input-checkbox--flex {
  align-items: center;

  .dc-input-checkbox__text {
    top: 0;
  }
}

// Input group
.dc-input-group {
  border-radius: $dc-border-radius;
  display: flex;
}

.dc-input-group__input {
  border-bottom-left-radius: 0;
  border-left: 0;
  border-top-left-radius: 0;
  box-sizing: border-box;
  display: inline-block;
  padding-left: 16px; // not offset by a 1px border
  width: 100%;
}

.dc-input-group__icon {
  background: $dc-green;
  border-bottom-left-radius: $dc-border-radius;
  border-top-left-radius: $dc-border-radius;
  box-sizing: border-box;
  color: $dc-navy-text;
  display: inline-flex;
  flex-shrink: 0;
  line-height: 1;
  padding: 15px; // 18px svg + 15px padding = 48px
  text-align: center;
  white-space: nowrap;
}
/** radio */

.dc-input-radio {
  display: inline-flex;
  line-height: 16px;
  position: relative;
}

.dc-input-radio + .dc-input-radio {
  margin-left: 16px;
}

/** input */

.dc-input-radio__input {
  display: block;
  height: 16px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 16px;
  z-index: -1;
}

/** indicator */

.dc-input-radio__indicator {
  align-items: center;
  background-color: $dc-white;
  border: 2px solid $dc-beige-400;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 16px;
  justify-content: center;
  position: relative;
  transition: border-color $dc-transition, box-shadow $dc-transition;
  width: 16px;

  &::before {
    border-radius: 50%;
    content: '';
    display: block;
    height: 8px;
    opacity: 0;
    transition: opacity $dc-transition;
    width: 8px;
  }

  .dc-has-error & {
    border-color: $dc-red;

    &::before {
      background: $dc-red;
    }
  }

  // disabled
  .dc-input-radio__input:disabled ~ & {
    opacity: 0.3;
    cursor: not-allowed;
  }

  // checked
  .dc-input-radio__input:checked ~ & {
    border-color: $dc-blue;

    &::before {
      background: $dc-blue;
      opacity: 1;
    }
  }

  // focus
  .dc-input-radio__input:focus ~ & {
    border-color: $dc-blue-dark;
  }

  // focus and checked
  .dc-input-radio__input:focus:checked ~ & {
    border-color: $dc-blue-dark;
  }

  // has error + checked
  .dc-has-error .dc-input-radio__input:checked ~ & {
    border-color: $dc-red;

    &::before {
      background: $dc-red;
      opacity: 1;
    }
  }

  // has error + focus
  .dc-has-error .dc-input-radio__input:focus ~ & {
    border-color: $dc-red-dark;
  }

  // has error + focus and checked
  .dc-has-error .dc-input-radio__input:focus:checked ~ & {
    border-color: $dc-red-dark;
  }
}

/** text */

.dc-input-radio__text {
  color: $dc-navy-text;
  flex-grow: 1;
  font-weight: $dc-font-weight-regular;
  line-height: $dc-line-height-base;
  margin-left: 8px;
  position: relative;
  top: -4px;
  transition: color $dc-transition;

  // disabled
  .dc-input-radio__input:disabled ~ & {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
%dc-input-search {
  background: $dc-white
    url('https://styleguide-assets.datacamp.com/images/enterprise/components/search/search-blue-6839bf2eb2.svg')
    no-repeat 16px center;
  border: 0;
  border-radius: 24px;
  box-shadow: $dc-box-shadow-sm;
  box-sizing: border-box !important; // Overwrite Normalize
  height: auto;
  line-height: 1.25;
  margin: 0;
  padding: 8px 16px 8px 42px;
  transition: box-shadow $dc-transition;
  width: 256px;

  &::-ms-clear {
    height: 0;
    width: 0;
  }

  &::placeholder {
    color: $dc-navy-text;
    opacity: 0.8;
  }

  &:active,
  &:focus,
  &:hover,
  &.dc-is-active {
    box-shadow: $dc-box-shadow-lg;
  }
}

.dc-input-search {
  @extend %dc-input-search;
}

.dc-input-search__filter {
  background: $dc-white;
  border-left: 1px solid $dc-grey-200;
  border-radius: 0 24px 24px 0;
  display: block;
  padding: 6px 17px 6px 12px;
  position: relative;

  &.dc-is-active {
    // filter active indicator
    &::after {
      background-color: $dc-red;
      border-radius: 50%;
      content: '';
      height: 6px;
      position: absolute;
      right: 9px;
      top: 9px;
      width: 6px;
    }
  }

  .dc-is-open & {
    .dc-icon--filter {
      color: $dc-green;
    }
  }
}
// -------------------------------------
//   Toggle Switch
// -------------------------------------

.dc-toggle-switch {
  align-items: center;
  display: flex;
  position: relative;
}

.dc-toggle-switch__input {
  height: 24px;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
}

.dc-toggle-switch__label {
  height: 24px;
  margin: 0 12px;
  padding: 0;
  position: relative;
  width: 48px;

  &::after {
    background-color: $dc-white;
    border: 1px solid $dc-grey-100;
    border-radius: 50%;
    box-shadow: $dc-box-shadow-md;
    content: '';
    display: block;
    height: 26px; // -1px border
    left: 0;
    position: absolute;
    top: -1px; // position vertically center over background
    transform: translate(0, 0);
    transition: all $dc-transition;
    width: 26px; // -1px border
  }

  &::before {
    background-color: $dc-blue;
    border: 0;
    border-radius: 24px; // 48px / 2
    box-sizing: border-box;
    content: '';
    display: block;
    height: 100%;
    margin-top: 0;
    top: 0;
    transition: all $dc-transition;
    width: 100%;
  }
}

.dc-toggle-switch__input:hover + .dc-toggle-switch__label::before {
  background-color: $dc-blue;
}

.dc-toggle-switch__input:checked + .dc-toggle-switch__label::after {
  transform: translateX(24px);
  transition: all $dc-transition;
}

.dc-toggle-switch__input:checked + .dc-toggle-switch__label::before {
  background-color: $dc-green;
  background-image: none;
  border: 0;
}
