.rmarkdown.content--tab-buttons {
  min-width: 240px;
  overflow: visible;

  .dc-btn-rounded-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    border-right: 1px solid $dc-white;
    padding: 0;

    .fa {
      margin: 0;
    }
  }

  .dc-btn-rounded-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
    padding: 4px spacing(5);
  }

  .exercise--reset {
    margin-right: spacing(2);
  }

  .dropdown-menu.dropup {
    right: auto;
    bottom: 100%;
    top: auto;

    a:hover,
    a:focus,
    a:active {
      background-color: $dc-grey-light;
    }
  }
}
