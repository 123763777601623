.lm_root {
  position: relative;
}

.lm_row > .lm_item {
  float: left;
}

.lm_content {
  overflow-y: visible;
  position: relative;
  border: 1px solid $dc-transparent-grey;
  border-top: 0;
}

.theme:not(.theme--light) [data-cy='top-left-stack'] .lm_content,
.theme:not(.theme--light) [data-cy='bottom-stack'] .lm_content {
  border: 1px solid $dc-navy;
  border-top: 0;
}

.lm_dragging,
.lm_dragging * {
  cursor: move !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.lm_maximised {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 40;
}

.lm_maximise_placeholder {
  display: none;
}

.lm_splitter {
  position: relative;
  z-index: 20;
}

.lm_splitter.lm_vertical .lm_drag_handle {
  position: absolute;
  height: 25px;
  top: -10px;
  cursor: n-resize;
  width: 100%;
}

.lm_splitter.lm_horizontal {
  height: 100%;
  float: left;
}

.lm_splitter.lm_horizontal .lm_drag_handle {
  position: absolute;
  width: 25px;
  left: -10px;
  cursor: e-resize;
  height: 100%;
}

/***********************************
* Drag Proxy
***********************************/
.lm_dragProxy {
  position: absolute;
  z-index: 30;
  top: 0;
  left: 0;
}

.lm_dragProxy .lm_content {
  overflow: hidden;
}

.lm_dropTargetIndicator {
  position: absolute;
  z-index: 20;
  display: none;
}

.lm_dropTargetIndicator .lm_inner {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.lm_transition_indicator {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 20px;
  height: 20px;
  z-index: 20;
}

.lm_popin {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 9999;
  bottom: 0;
  right: 0;
}

.lm_popin > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.lm_popin > .lm_bg {
  z-index: 10;
}

.lm_popin > .lm_icon {
  z-index: 20;
}

// -------------------------------------
//   Slides - Overide
// -------------------------------------

.lm_dragging .slides-wrapper::before {
  align-items: center;
  background: $dc-white;
  content: '';
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

// ----- Slides Loading Spinner ----- //

// Hide
.slides-wrapper .dc-is-loading {
  display: none;
}

// Show
.lm_dragging .slides-wrapper .dc-is-loading {
  display: flex;
}
