.lm_goldenlayout {
  border-radius: $dc-border-radius;
}

.lm_content {
  background: $dc-white;
  border-radius: 0px 0px 4px 4px;
}

.lm_dragProxy .lm_content {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.lm_dropTargetIndicator {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.4);
  outline: 1px dashed #ccc;
  margin: 1px;
  transition: all 200ms ease;
}

.lm_dropTargetIndicator .lm_inner {
  background: #000;
  opacity: 0.1;
  filter: alpha(opacity=1);
}

.lm_splitter {
  transition: opacity 200ms ease;
  z-index: 100;
}

.lm_splitter:hover {
  background: rgba(5, 25, 45, 0.05);
}

.lm_transition_indicator {
  background-color: #000;
  border: 1px dashed #555;
}

.lm_popin {
  cursor: pointer;
}

.lm_popin .lm_bg {
  background: #000;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.lm_popin .lm_icon {
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.7;
  filter: alpha(opacity=70);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAJCAQAAABDc27hAAAAKUlEQVR42mN4958BCt79R4VgITCGAoRCKIVNCkzgkIIBTCkgoroULggAqJJLHAYSJv4AAAAASUVORK5CYII=);
}

.lm_popin:hover .lm_icon {
  opacity: 1;
  filter: alpha(opacity=100);
}

.lm_splitter.lm_horizontal .lm_drag_handle {
  cursor: ew-resize;
}

.lm_splitter.lm_vertical .lm_drag_handle {
  cursor: ns-resize;
}
