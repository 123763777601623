.skills-tooltip {
  background-color: $dc-navy-text;
  border-radius: $dc-border-radius;
  color: $dc-white;
  font-size: $dc-font-size-small;
  font-weight: $dc-font-weight-light;
  padding: 2px 12px;
}

.tooltip {
  z-index: 9999 !important;
}

.progress-tooltip {
  font-size: 0.8rem;
  font-weight: $dc-font-weight-light;
  border-radius: $dc-border-radius;
  border: 1px solid $dc-blue;
  color: $dc-navy-text;
  line-height: 24px;
  width: 80px;
  text-align: center;

  .fa {
    margin-right: 3px;
  }

  &.completed,
  &.inbetween {
    border-color: $dc-transparent-grey;
    color: rgb(146, 200, 218);
  }
}

.progress-tooltip.center {
  padding-left: 0;
  text-align: center;
}

.aggregated-tooltip {
  width: 300px;
  display: block;

  .tooltip-skill {
    text-align: left;
    display: block;
  }
}
