// *************************************
//
//   Light Theme
//   -> Default Editor & Console Theme
//
// *************************************

// -------------------------------------
//   LM - Golden Layout
// -------------------------------------

// This is a temporary hack until we separate the exercise area and the editor/console area

.theme--light {
  .lm_header {
    background-color: $dc-grey-medium;
  }

  .lm_controls {
    border-color: $dc-white;
  }

  .lm_controls .lm_tabdropdown:before {
    color: black;
  }

  .lm_tab {
    color: rgba($dc-navy-text, 0.5);
    border-right: 1px solid $dc-white;

    &:hover {
      color: $dc-navy-text;
    }

    &.lm_active {
      background-color: $dc-white;
      color: $dc-navy-text;
    }
  }

  // -------------------------------------
  //   Console
  // -------------------------------------

  .console--wrapper-toggle {
    background: $dc-grey-light;
    border-left: 1px solid $dc-navy;
  }

  // -------------------------------------
  //   Buttons
  // -------------------------------------

  .content--tab-buttons {
    background: linear-gradient(
        0deg,
        $dc-grey-light 0%,
        #f7f7fccc 25%,
        #f7f7fc00 50%,
        #f7f7fc00 100%
      );
  }

  // ----- Run/try Code - Reset ----- //

  .dc-try-code-btn,
  .exercise--reset {
    color: $dc-navy-text;

    &:active,
    &:focus,
    &:hover {
      color: $dc-navy-text;
    }
  }

  // ----- Reactivate ----- //

  .dc-reconnecter {
    background: $dc-white;

    h3,
    p {
      color: $dc-navy-text;
    }

    a {
      color: $dc-blue-text;
      &:hover {
        border-bottom: 1px solid $dc-blue;
      }
    }
  }

  // ----- Expand Icon Button - Plot/HTML ----- //

  .dc-expand-toggle-icon {
    color: $dc-navy-text;
    &:active,
    &:focus,
    &:hover {
      color: $dc-navy-text;
    }
  }
}
