.course--completed-check {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
}

.course--author-image {
  position: absolute;
  width: 40px;
  border-radius: 50%;
}

.course--author-name {
  padding-left: 50px;
  min-height: 40px;
  position: relative;

  span {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.course--progress {
  position: absolute;
  bottom: 0;
  padding: 10px;
  left: 0;
  right: 0;

  .progress {
    width: 60%;
    margin: 0 auto;
  }
}
