.multiple-choice__wrapper {
  display: flex;
}

.multiple-choice__container {
  background: $dc-white;
  border-radius: $dc-border-radius;
  border: 1px solid $dc-grey-light;
  margin: auto;
  max-width: 900px;
  position: relative;
  width: 100%;

  .exercise--title {
    font-size: dc-font-size-h3;
  }
}

.dc-input-radio__input:checked ~ .dc-input-radio__indicator {
  border-color: $dc-blue;
  &:before {
    background: $dc-blue;
  }
}
.dc-input-radio__input:focus:checked ~ .dc-input-radio__indicator {
  border-color: $dc-blue;
}

.multiple-choice__item {
  margin-top: spacing(3);
}

.multiple-choice__shortcut {
  min-width: 85px;
}

.multiple-choice__options {
  align-items: center;

  input[type='radio'] + label {
    color: $dc-navy-text;
  }

  input[type='checkbox'] + label:before,
  input[type='radio'] + label:before {
    top: 2px;
  }
}

.multiple-choice__actions {
  padding-top: spacing(5);

  .exercise--buttons {
    z-index: 1;
    top: -24px;
    position: relative;
    margin: 0;
  }
}

/**
 * Everything below this line should be reviewed and refactored.
 */

.plain-mc-ex {
  #rendered-view {
    overflow: auto;
  }

  #rendered-view > div {
    height: 100%;
  }

  .sidebar-overlay {
    position: fixed;
    top: 50px; //nav-bar
  }
}

ul.exercise--multiple-choice {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .enumeration {
    display: none;
  }
}

.exercise--multiple-choice-focused {
  ul li .enumeration {
    display: inline-block;
    width: 20px;
    float: left;
    color: $dc-grey-light;
    line-height: 1.55;
  }

  ul li .mc-label {
    margin-left: 0;
    margin-right: -20px;
    padding-right: 20px;
  }
}

.dc-input-radio {
  cursor: pointer;
}
