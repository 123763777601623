.instructions--tab {
  position: relative;

  .active-tab-info {
    display: flex;
    align-items: center;

    /**
    * This is a temporary hack until the tab component is refactored
    * Blame Dan
    */

    position: absolute;
    top: -36px;
    right: 0;
    left: auto;
    z-index: 1;
  }
}

.instructions--tab__title {
  margin-right: spacing(1);
  margin: 0;
  top: 1px;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 54px;
  overflow: visible;
  border-bottom: 1px solid #e3e7e8;

  li:first-child .tab-line {
    display: none;
  }

  li {
    display: flex;
    align-items: center;

    .tab-line {
      height: 1px;
      background: $dc-grey-light;
      width: 15px;
    }
  }

  &.content--tab li a {
    padding: 0;
  }

  .tab-icon {
    top: 4px;
  }
}

.instructions--tab-body {
  .exercise-instructions {
    padding: spacing(4);
  }

  .exercise--buttons {
    position: relative;
    z-index: 1;
  }
}
