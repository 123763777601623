.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: $dc-blue-text;
}

.text-secondary {
  color: $dc-yellow-text;
}

.text-tertiary {
  color: $dc-navy-text;
}

.text-invert-secondary {
  color: $dc-grey-light;
}

small {
  color: $dc-grey-light;
}

.no-link-style {
  border-bottom: none;

  &:hover {
    //color: $dc-navy-text;
    //color: inherit;
  }

  &:focus {
    border-bottom: none;
    //color: inherit;
  }
}

.subhead {
  text-transform: uppercase;
  color: $dc-grey-light;
  font-weight: $dc-font-weight-light;
  margin-top: 1rem;
  letter-spacing: 5px;
}

blockquote {
  background-color: $dc-grey-light;
  border-left: 2px solid $dc-blue;
  margin: 0;
  margin-bottom: 2rem;

  &.text-center {
    border-left: none;
  }

  p {
    margin: 0 0 0.25em;
    font-style: italic;
    color: $dc-navy-text;
  }

  cite {
    color: $dc-grey-light;
    font-size: $dc-font-size-base;
    font-style: normal;
    text-transform: uppercase;

    &:before {
      display: inline-block;
      content: open-quote;
      margin-right: 5px;
    }
  }
}

.callout {
  color: $dc-navy-text;
  font-style: italic;
  font-size: dc-font-size-h3;
  margin: 1.5em 0 1em;
}

pre {
  overflow-x: auto;
  transition: max-width 0.25s, margin 0.25s;
  word-break: break-word;
}

mark {
  color: $dc-navy;
  font-weight: $dc-font-weight-bold;
  margin: 0 0.3em;
  padding: 0.3em 0.4em;
}

mark::-moz-selection,
mark::selection {
  background-color: $dc-navy;
  color: white;
}

dd {
  margin-bottom: 1.2em;
  margin-left: 0;
}

dt {
  color: $dc-navy-text;
  font-weight: $dc-font-weight-bold;
  text-transform: uppercase;
}
