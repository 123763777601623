.exercise--instructions-title {
  position: relative;
}

/**
 * Everything below this line should be reviewed and refactored.
 */

.exercise--instructions {
  margin-bottom: 20px;
  position: relative;
  padding: 15px 15px;
  background-color: $dc-white;
  overflow: hidden;

  .exercise--instructions__content {
    ul + p {
      margin-top: 10px;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
  }
}
