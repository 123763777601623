// Legacy scss from old waffles-core stylings
// TODO: Should be removed once externals are also migrated to latest waffles

@import './waffles-legacy/variables.scss';
@import './waffles-legacy/typography.scss';
@import './waffles-legacy/utilities.scss';
@import './waffles-legacy/mixins.scss';
@import './waffles-legacy/legacy.scss';
@import './waffles-legacy/forms.scss';

// Used in helpers/tabScroll.tsx
.tab-scroll-legacy {
  color: $dc-blue;
  padding: 8px;
  align-self: center;
  position: absolute;
  cursor: pointer;
  z-index: 50;
}
