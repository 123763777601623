/**
 * This is a bad hack to raise the z-index of the editor header, but not the console header
 * If both are raised, dragging is broken
 * If the editor isn't raised, the buttons go above it when the panel is shrunk manually
 */

/* stylelint-disable */
#rendered-view
> .lm_goldenlayout
> .lm_row
> .lm_column
> .lm_row
> .lm_stack
> .lm_header {
  z-index: 1;
}
/* stylelint-enable */

/**
 * Header
 */

.lm_header {
  display: flex;
  border-radius: $dc-border-radius $dc-border-radius 0 0;
  background: $dc-navy-light;
  position: relative;
  overflow: hidden;
  &.lm_selectable {
    cursor: pointer;
  }
}

/**
 * Tabs
 */

.lm_tabs {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  position: absolute;
  border-radius: $dc-border-radius $dc-border-radius 0 0;
  display: flex;
  width: calc(100% - 31px);
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 30px;
}

.lm_tab {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 1px solid transparent;
  border-right: 1px solid $dc-navy;
  color: rgba($dc-white, 0.5);
  cursor: pointer;
  font-size: 14px;
  font-weight: $dc-font-weight-bold;
  font-family: Studio-Feixen-Sans, Arial, sans-serif;
  height: 32px;
  padding: 0 16px;
  transition: background-color $dc-transition, color $dc-transition;

  &:first-child {
    border-radius: $dc-border-radius 0 0 0;
  }

  &:hover {
    color: $dc-white;
  }

  &.lm_active {
    background-color: $dc-navy;
    color: $dc-white;

    &:first-child {
      border: 1px solid $dc-transparent-grey;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }
}

/**
 * Controls
 */

.lm_controls {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  position: absolute;
  right: 0;
  height: 30px;
  width: 30px;
  border-radius: 0 $dc-border-radius 0 0;
  transition: color $dc-duration ease;
}

.lm_controls > li {
  width: 18px;
  height: 18px;
  float: left;
  text-align: center;
  cursor: pointer;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  opacity: 0.4;
  filter: alpha(opacity=40);
  -webkit-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
  -ms-transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.lm_controls .lm_tabdropdown:before {
  color: $dc-white;
  content: '';
  width: 0;
  height: 0;
  vertical-align: middle;
  display: inline-block;
  border-top: 5px dashed;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.lm_controls > li:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.lm_controls .lm_popout {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAIklEQVR4AWNAAv9xYTRFmIAERYSt+U/IBMKKYJhyB5MUPgBHWCTcsLSkgAAAAABJRU5ErkJggg==);
}

.lm_controls .lm_maximise {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAFklEQVR4AWMAgv+EMAmK8IChr4gQBgASxSbaA46jYgAAAABJRU5ErkJggg==);
}

.lm_maximised .lm_controls .lm_maximise {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJAQAAAADI6ju9AAAAAnRSTlMAAQGU/a4AAAAPSURBVHgBY/jfgAU1gBAAtKQMer1Q8pMAAAAASUVORK5CYII=);
}

.lm_controls .lm_close {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAJ0lEQVR4AWMAgv8M+MF/OAOXAkIC//EbjaAJu4E8kwi7ibDvCIcTAOvuEPAQT2ZkAAAAAElFTkSuQmCC);
}

/**
 * From Base.scss
 * Everything below this line should be refactored, but has to be checked with courses that display the items.
 */

.lm_column,
.lm_item {
  border-radius: $dc-border-radius;
}

.lm_header .lm_tab i {
  height: 19px;
  width: 2px;
  position: absolute;
}

.lm_header .lm_tab i.lm_left {
  left: 0;
  top: 0;
}

.lm_header .lm_tab i.lm_right {
  right: 0;
  top: 0;
}

.lm_header .lm_tab .lm_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.lm_header .lm_tab .lm_close_tab {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  opacity: 0.4;
  width: 11px;
  height: 11px;
  right: 6px;
  top: 4px;
  background-position: center center;
  background-repeat: no-repeat;
  filter: alpha(opacity=40);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAIElEQVR4AWMAgv8M2MF/dAaCjy4ApwkZRVAnXjvxuhYAEAIM9EkbaSsAAAAASUVORK5CYII=);
}

.lm_header .lm_tabdropdown_list {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1;
}

.lm_header .lm_tabdropdown_list .lm_tab {
  clear: both;
  padding-right: 10px;
}

.lm_header .lm_tabdropdown_list .lm_tab .lm_title {
  width: 100px;
}

.lm_header .lm_tabdropdown_list .lm_close_tab {
  display: none !important;
}

.lm_drop_tab_placeholder {
  width: 100px;
  height: 10px;
  float: left;
  visibility: hidden;
}

.lm_dragProxy .lm_header {
  background: transparent;
}

/**
 * From Light.scss
 */

.lm_selected .lm_header {
  background-color: #452500;
}

.lm_maximised .lm_header {
  background-color: $dc-white;
}

.lm_header .lm_tab .lm_close_tab:hover,
.lm_header .lm_tab.lm_active .lm_close_tab {
  opacity: 1;
  filter: alpha(opacity=100);
}
