// CSS reset
@import '~sanitize.css';

// overriding to avoid an issue with rendering the original character with the wrong charset
:where(nav li)::before {
  content: "";
  float: left;
}

// Waffles core styles
@import './fonts.scss';
@import './variables.scss';

// Legacy Waffles styles - to be removed after external dependencies are migrated to New Waffles
@import './waffles-legacy.scss';

// Shared learn styles
@import '~@datacamp/le-shared-components/styles/dc-learn.scss';

// Campus
@import './campus/_globals.scss';
@import './campus/_inbox.scss';
@import './campus/_bullet-exercise.scss';
@import './campus/_completed.scss';
@import './campus/_dropdowns.scss';
@import './campus/_exercise-area.scss';
@import './campus/_exercise-footer.scss';
@import './campus/_exercise-instructions.scss';
@import './campus/_lm-header.scss';
@import './campus/_multiple-choice.scss';
@import './campus/_tab-exercise.scss';
@import './campus/_monaco.scss';

@import './styleguide/partials/_filter.scss';
@import './styleguide/partials/_grid.scss';
@import './styleguide/partials/_tables.scss';
@import './styleguide/partials/_typography.scss';

@import './partials/_animations.scss';
@import './partials/_course-outline-modal.scss';
@import './partials/_course.scss';
@import './partials/_editor.scss';
@import './partials/_feedback.scss';
@import './partials/_icons.scss';
@import './partials/_instruction-sidebar.scss';
@import './partials/_markdown.scss';
@import './partials/_modals.scss';
@import './partials/_navigation.scss';
@import './partials/_report_exercise.scss';
@import './partials/_sct.scss';
@import './partials/_slides.scss';
@import './partials/_tooltips.scss';
@import './partials/_layout.scss';
@import './partials/_mobile-popup.scss';

@import './styleguide/golden-layout/base.scss';
@import './styleguide/golden-layout/light.scss';

@import './refactor.scss';

// Components
@import '../components/Console/Reconnecter.scss';
@import '../components/Assignment/ExerciseCompleted/ExerciseCompleted.scss';

// Shared components
@import '../shared-components/ListView/ListView.scss';

// Theme
@import './campus/_theme-light.scss';

// Deprecated Bootstrap legacy styles
@import './vendor/bootstrap/_bootstrap-deprecated';

a {
  text-decoration: none;
}
