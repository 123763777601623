/** Spatial */

$dc-multiplier: 2;
// Spatial lists for generating utility classes
$dc-spatial: 2 4 8 12 16 24 32 36 48 64 80 96 128;
$dc-spatial-common: 8 12 16 24 32 36 48 64;
$dc-measure: 160 192 256 320 480 640 800 960;
$dc-spatial-all: join(
    $list1: $dc-spatial,
    $list2: $dc-measure,
  );
// Percentages list for generating utility classes
$dc-percentages: 1 5 10 15 20 25 30 40 44 50 60 70 75 80 90 100;
$dc-angles: 30 45 60 90 180 270 360;
$dc-z-indexes: 10 20 30 40 50 60 70 80 90 100 999;

/** Color */
$dc-blue: #06bdfc;
$dc-blue-dark: #009bd8;
$dc-blue-light: #60e7ff;
$dc-blue-text: #0065d1;
$dc-green: #03ef62;
$dc-green-dark: #00c53b;
$dc-green-light: #65ff8f;
$dc-green-text: #008700;
$dc-navy: #05192d;
$dc-navy-dark: #000820;
$dc-navy-light: #213147;
$dc-navy-text: #05192d;
$dc-orange: #ff931e;
$dc-orange-dark: #d87300;
$dc-orange-light: #ffbc4b;
$dc-orange-text: #b75900;
$dc-pink: #ff6ea9;
$dc-pink-dark: #dc4d8b;
$dc-pink-light: #ff95cf;
$dc-pink-text: #bf3072;
$dc-purple: #7933ff;
$dc-purple-dark: #5646a5;
$dc-purple-light: #974dff;
$dc-purple-text: #5646a5;
$dc-red: #ff5400;
$dc-red-dark: #dd3400;
$dc-red-light: #ff782d;
$dc-red-text: #c01100;
$dc-yellow: #fcce0d;
$dc-yellow-dark: #cfa600;
$dc-yellow-light: #ffec3c;
$dc-yellow-text: #907000;
$dc-white: #ffffff;
$dc-grey-subtle: #f7f7fc;
$dc-grey-light: #efeff5;
$dc-grey: #e8e8ee;
$dc-grey-medium: #e1e1e8;
$dc-grey-dark: #848492;
$dc-transparent-grey: rgba(48, 57, 105, 0.15);
$dc-transparent-grey-soft: rgba(48, 57, 105, 0.1);
$dc-transparent-grey-subtle: rgba(48, 57, 105, 0.6);
$dc-transparent-white: rgba(255, 255, 255, 0.15);
$dc-transparent-white-soft: rgba(255, 255, 255, 0.1);
$dc-transparent-white-subtle: rgba(255, 255, 255, 0.06);

/** Typography */

// Font family
$dc-font-family-sans-serif: 'Studio-Feixen-Sans', Arial;
$dc-font-family-mono: 'JetBrainsMonoNL', monospace;

// Font size
$dc-font-size-micro: 0.75rem;
$dc-font-size-small: 0.875rem;
$dc-font-size-h6: 1rem;
$dc-font-size-h5: 1.125rem;
$dc-font-size-h4: 1.25rem;
$dc-font-size-h3: 1.5rem;
$dc-font-size-h2: 2rem;
$dc-font-size-h1: 2.5rem;
$dc-font-size-base: 14px;

// Font weight
$dc-font-weight-bold: 800;
$dc-font-weight-light: 100;
$dc-font-weight-regular: 400;

// Line height
$dc-line-height-base: 1.5;
$dc-line-height-heading: 1.2;
$dc-line-height-large-heading: 1.05;

/** Border */
$dc-border-radius: 4px;

/** Box shadow */
$dc-box-shadow-border: 0 0 0 1px rgba(5, 25, 45, 0.1);
$dc-box-shadow-color: rgba(5, 25, 45, 0.3);
$dc-box-shadow-lg: 0 3px 5px -1px rgba(5, 25, 45, 0.3);
$dc-box-shadow-md: 0 2px 4px -1px rgba(5, 25, 45, 0.3);
$dc-box-shadow-sm: 0 1px 4px -1px rgba(5, 25, 45, 0.3);
$dc-box-shadow-xl: 0 8px 12px -4px rgba(5, 25, 45, 0.3);
$dc-box-shadow-xl-green: 0 4px 8px 0 rgba(54, 213, 125, 0.3);
$dc-box-shadow-xl-orange: 0 4px 8px 0 rgba(255, 148, 0, 0.3);
$dc-box-shadow-xl-purple: 0 4px 8px 0 rgba(132, 104, 196, 0.3);
$dc-box-shadow-xxl: 0 10px 20px 0 rgba(5, 25, 45, 0.3);

/** Animation */
$dc-duration: 0.3s;
$dc-ease: cubic-bezier(0.77, 0, 0.175, 1);
$dc-transition: 0.3s cubic-bezier(0.77, 0, 0.175, 1);

/* Letter Spacings */
$dc-letter-spacing-base: 0px;
$dc-letter-spacing-medium-heading: -0.5px;
$dc-letter-spacing-large-heading: -1px;

/** Breakpoints */
$dc-bp-xs: 480px;
$dc-bp-sm: 768px;
$dc-bp-md: 992px;
$dc-bp-lg: 1200px;
$dc-bp-xl: 1366px;
$dc-bp-ws: 1680px;
$dc-bp-4k: 1920px;
$dc-bp-5k: 2560px;

$dc-bp-below-xs: 479px;
$dc-bp-below-sm: 767px;
$dc-bp-below-md: 991px;
$dc-bp-below-lg: 1199px;
$dc-bp-below-xl: 1365px;
$dc-bp-below-ws: 1679px;
$dc-bp-below-4k: 1919px;
$dc-bp-below-5k: 2559px;

$dc-breakpoints: (
    'xs': $dc-bp-xs,
    'sm': $dc-bp-sm,
    'md': $dc-bp-md,
    'lg': $dc-bp-lg,
    'xl': $dc-bp-xl,
  );
$dc-breakpoints-hd: (
    'ws': $dc-bp-ws,
    '4k': $dc-bp-4k,
    '5k': $dc-bp-5k,
  );
$dc-breakpoints-all: map-merge($dc-breakpoints, $dc-breakpoints-hd);
$dc-breakpoints-below: (
    'xs': $dc-bp-below-xs,
    'sm': $dc-bp-below-sm,
    'md': $dc-bp-below-md,
    'lg': $dc-bp-below-lg,
    'xl': $dc-bp-below-xl,
  );
$dc-breakpoints-below-hd: (
    'ws': $dc-bp-below-ws,
    '4k': $dc-bp-below-4k,
    '5k': $dc-bp-below-5k,
  );
$dc-breakpoints-below-all: map-merge(
    $dc-breakpoints-below,
    $dc-breakpoints-below-hd
  );

$dc-sides: (
    't': top,
    'r': right,
    'b': bottom,
    'l': left,
  );
