.mute-icon {
  color: white;
  width: 20px;
  line-height: 28px;
  display: inline-block;
  i {
    color: white;
  }
  i:hover {
    color: white;
  }
}