table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}
tr {
  border-bottom: 1px solid $dc-grey-light;
}
.theme:not(.theme--light) .console-sql-container {
  background-color: $dc-navy;

  tr:nth-of-type(2n) {
    background-color: transparent !important;
  }
  tr {
    background-color: transparent !important;
  }
  table {
    background-color: transparent !important;
  }
  tbody td,
  .sql-query-message {
    color: $dc-white;
  }
  thead th {
    color: $dc-yellow;
  }
  td,
  th {
    border-color: $dc-white;
  }
}
tr:nth-of-type(2n) {
  background: $dc-grey-subtle;
}
th {
  border-bottom: 1px solid $dc-navy;
  color: $dc-navy-text;
}
td,
th {
  padding: 10px;
}
td {
  border-top: 1px solid $dc-grey-light !important;
  color: $dc-yellow-text;
}
