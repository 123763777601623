#report-exercise {
  .modal-header {
    padding: 32px 32px 24px 32px;
  }

  .modal-body {
    padding: 0 32px 32px;

    #category {
      margin-bottom: 15px;
    }

    .form-group {
      margin: 0;

      textarea {
        resize: vertical;
      }
    }

    #extra-information {
      height: 10em;
    }
  }
}
