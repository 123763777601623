// ----- Content ----- //

.dc-sct-feedback__campus-content {
  padding: 16px;
  color: $dc-navy-text p {
    margin: 0;
  }

  li {
    margin-left: 16px;
    list-style: disc;

    + li {
      margin-top: 8px;
    }
  }

  h5 {
    font-weight: 600;
    font-size: 1rem;
  }

  a {
    color: $dc-navy-text;
    text-decoration: underline;
  }

  code,
  a > code {
    color: $dc-white;
    white-space: pre-wrap;
  }
}
