$baseline: 5px;

/**
 * Outdated mixin that is in use in multiple apps
*/

@mixin translateX($x) {
  transform: translate($x, 0);
}

@mixin translateY($y) {
  transform: translate(0, $y);
}

@mixin translate($x, $y) {
  transform: translate($x, $y);
}

/**
   * Outdated mixin that is in use in multiple apps
   * Also has related utilities in use
   *
  */

@mixin v-align {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin h-align {
  display: block;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

@mixin vh-align {
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

/**
   * Outdated mixin that is in use in multiple apps
   * Also has related utilities in use
   *
  */
@mixin input-placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

/**
   * Outdated mixin that is in use in multiple apps
   * Also has related utilities in use
   *
  */
@mixin input-focus-placeholder {
  &:focus::-webkit-input-placeholder {
    @content;
  }

  &:focus:-moz-placeholder {
    @content;
  }

  &:focus::-moz-placeholder {
    @content;
  }

  &:focus:-ms-input-placeholder {
    @content;
  }
}

/** clearfix mixin */

@mixin clearfix {
  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

/**
   * Enable flexbox
   *
   * To change an element's display state from block or inline to flex, you
   * can use this mixin.
   */

@mixin flexbox() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/**
   * Center content along the main axis
   * For your convenience, this mixin also sets the display property to flex.
   * See example alignment depending on the flex-direction where
   * the **** represents the content inside our container.
   *
   *
   * Example in a horizontal (row) setup:
   *    |------------------|
   *    |       ****       |
   *    |                  |
   *    |                  |
   *    |------------------|
   *
   *
   * Example in a vertical (column) setup (height needs to be set for container):
   *    |------------------|
   *    |                  |
   *    |****              |
   *    |                  |
   *    |------------------|
   */

@mixin flexbox-justify-center {
  @include flexbox();

  justify-content: center;
}

/**
   * Center content perpendicular to the main axis
   * For your convenience, this mixin also sets the display property to flex.
   * See example alignment depending on the flex-direction where
   * the **** represents the content inside the container.
   *
   *
   * Example in a horizontal (row) setup (height needs to be set for container):
   *    |------------------|
   *    |                  |
   *    |****              |
   *    |                  |
   *    |------------------|
   *
   *
   * Example in a vertical (column) setup:
   *    |------------------|
   *    |       ****       |
   *    |                  |
   *    |                  |
   *    |------------------|
   */

@mixin flexbox-align-center {
  @include flexbox();

  align-items: center;
}

/**
   * Center content both horizontally and vertically using flexbox
   * For your convenience, this mixin also sets the display property to flex.
   * This works independent of the flex-direction since content will
   * be centered on both axes.
   */

@mixin flexbox-dead-center {
  @include flexbox();

  align-items: center;
  justify-content: center;
}

/**
   * Outdated mixin that is in use in multiple apps
   * Also has related utilities in use
   *
  */

/**
   * Spacing function
   * Use this spacing function to add padding or margin to an element.
   * Pass in an optional offset value to compensate for borders when
   * you add padding to your element. This will ensure that the outer
   * dimensions of the box model remain the same.
   * Do not use this for setting a custom width or height.
   *
   * Example use case: `margin-right: spacing(3, $offset: 1px);` = 14px
   */

@function spacing($multiplier, $offset: 0) {
  @return $multiplier * $baseline - $offset;
}

/**
   * Spacing (margin) utility classes
   *
   * The mixin and loop below will generate spacing utility classes
   * using the "spacing" function to create 5px increments.
   * Use these classes to remove or add margin all around or
   * to remove or add margin to one specific side.
   * Increments range from 0 to 10, providing up to 50px of margin.
   *
   * Example output:
   *   .u-spacing-0   -- sets margin all around to 0
   *   .u-spacing-l0  -- sets margin-left to 0
   *   .u-spacing-1   -- adds 5px margin all around
   *   .u-spacing-r2  -- adds 10px margin-right
   *   .u-spacing-b5  -- adds 25px margin-bottom
   */

@mixin spacing-classes($side) {
  $class-slug: '';
  $margin: '';

  @for $i from 0 through 10 {
    @if $side == all {
      $class-slug: spacing-#{$i};
      $margin: margin;
    } @else {
      $side-abbr: str-slice($side, 1, 1);
      $class-slug: spacing-#{$side-abbr}#{$i};
      $margin: margin-#{$side};
    }

    .u-#{$class-slug} {
      #{$margin}: spacing($i) !important;
    }
  }
}

$sides: all, top, right, bottom, left;

@each $side in $sides {
  @include spacing-classes($side);
}

@mixin dc-edge-to-edge {
  display: flex;
  justify-content: space-between;
}

.dc-edge-to-edge {
  @include dc-edge-to-edge;
}

.dc-edge-to-edge--align-center {
  align-items: center;
}
