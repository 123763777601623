// *************************************
//
//   Globals
//   -> Specific to campus
//
// *************************************

// -------------------------------------
//   Base
// -------------------------------------

html,
body {
  height: 100%;
}

body {
  background-color: $dc-grey-subtle;
}

// -------------------------------------
//   Variables
// -------------------------------------

// ----- Colors ----- //

$primary-ultra-light: #fafeff;
