// This style was created solely for the completed panel while
// decoupling the xp components for the course outline XP and the completed panel XP,
// TODO: But there are remnants / refactors of styles in _completed.scss to finish the job.
.dc-exercise-completed {
  border: 0;
  font-size: dc-font-size-h5;
  font-weight: $dc-font-weight-regular;
  color: $dc-white;

  .icon-wrapper {
    position: relative;
    top: 2px;
  }

  &.partial-xp {
    color: $dc-yellow-text;
  }

  &.all-xp {
    color: $dc-green;
  }
}

@keyframes checkmarkStroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes rotateCheckmark {
  60% {
    transform: rotate(-15deg);
  }

  80% {
    transform: rotate(15deg);
  }

  85% {
    transform: rotate(-10deg);
  }

  90% {
    transform: rotate(10deg);
  }

  95% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes clipCheckmark {
  0% {
    clip-path: polygon(25.96% 38.41%, 25.06% 55.36%, 30.58% 51.45%);
  }

  2% {
    clip-path: polygon(
        16.19% 82.12%,
        3.75% 101.23%,
        -1.34% 90.52%,
        6.71% 50.27%
      );
  }

  78% {
    clip-path: polygon(
        99.4% -1.88%,
        100.35% 32.31%,
        1.44% 101.23%,
        -1.34% 90.52%,
        7.63% 50.27%,
        92.76% -0.11%
      );
  }
}

.dc-exercise-completed__checkmark-end {
  clip-path: none;
  animation: clipCheckmark cubic-bezier(0.65, 0, 0.45, 0.5) 2s;
  will-change: clip-path;
}

.dc-exercise-completed__checkmark-main {
  animation: checkmarkStroke cubic-bezier(0.65, 0, 0.45, 0.5) 2s forwards;
  stroke-dasharray: 9800;
  stroke-dashoffset: 9800;
  will-change: stroke-dashoffset;
}

.dc-exercise-completed__checkmark {
  color: $dc-white;
  width: 50px;
  height: 50px;
  animation: rotateCheckmark 1.75s alternate;
}
