$closed-sidebar-width: 32px;

.exercise--sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  border-radius: $dc-border-radius;

  &.narrow {
    width: 33%;
  }

  .listview__header {
    cursor: pointer;
    height: 33px; // 32px height + 1 px of border to have separators
    border-bottom: 1px solid $dc-white;
  }
}

// Holds the content put inside the list view headers
.exercise--sidebar-header {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 15px;
  justify-content: space-between;
}

.exercise--sidebar-toggle {
  align-items: center;
  background: $dc-grey-light;
  border: none;
  border-left: 1px solid $dc-white;
  border-radius: 0 $dc-border-radius 0 0;
  display: flex;
  height: $closed-sidebar-width;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: $closed-sidebar-width;
  z-index: 3;

  > svg {
    color: $dc-navy-text;

    &.closed {
      transform: scale3d(-1, 1, 1);
    }
  }
}

div.resize-handler {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
}

.exercise--documentation {
  display: block;
  position: absolute;
  border-bottom: 5px solid $dc-grey-light;
  background-color: white;
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.exercise--sidebar,
.exercise--capstone {
  a {
    @extend .no-link-style;
  }
}

.exercise--sidebar.small {
  width: 25%;
}

[data-cy='aside-stack'] {
  .lm_content {
    border-radius: $dc-border-radius;
    border-top: 1px solid $dc-transparent-grey;
  }
  .gl-content {
    overflow: hidden;
  }
}

.gl-content aside {
  height: 100%;
  width: 100%;
}

.exercise--sidebar-content {
  height: 100%;
  overflow: auto;

  .closed & {
    transform: translate3d(-$closed-sidebar-width, 0, 0);
  }

  pre > code {
    margin: 0;
  }
}

.exercise--sidebar-content.half {
  height: 40%;
  overflow: auto;
  padding: 20px;
}

.exercise--buttons {
  margin: 15px 0;
  position: relative;
  z-index: 1;
}

.exercise--title {
  font-size: $dc-font-size-h4;
  margin-bottom: 15px;
  color: $dc-navy-text;
}

.exercise--sidebar-content,
.exercise--capstone {
  .skills-tooltip {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 3px;
  }
}

@media screen and (min-width: $dc-bp-lg) {
  pre {
    margin-left: 0 !important;
    max-width: none !important;
  }
}
