.filter-arrow {
  margin-left: 1.5em;
  position: relative;

  &:before,
  &:after {
    display: block;
    content: '';
    background: $dc-navy;
    border-radius: $dc-border-radius;
    width: 0.125em;
    height: 0.625em;
    position: absolute;
    transition: background $dc-duration ease;
  }
  &:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0.3125em;
  }
  &:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 0.3125em;
  }
}

.filter-down {
  &:before {
    right: 0.3125em;
  }
  &:after {
    right: 0.6875em;
  }
}

.filter-up {
  &:before {
    right: 0.6875em;
  }
  &:after {
    right: 0.3125em;
  }
}
