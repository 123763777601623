.dc-reconnecter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
  text-align: center;
  background: $dc-navy;
  color: $dc-white;

  h3,
  p {
    color: $dc-white;
  }

  a {
    border-bottom: none;
    color: $dc-green;
    &:hover {
      border-bottom: 1px solid $dc-green;
    }
  }

  .dc-btn {
    margin-top: spacing(2);
  }
}

.dc-reconnecter__image {
  margin-right: spacing(2);
}

.plain-mc-ex {
  .dc-reconnecter {
    position: static;

    & > div {
      position: static;
      transform: none;
    }
  }
}
