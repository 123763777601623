.exercise--content {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60%;
  right: 0;
  background: none;
  border: 5px solid $dc-grey-light;

  &.wide {
    width: 67%;
  }
}

.content--top {
  position: absolute;
  top: 0;
  bottom: 40%;
  left: 0;
  right: 0;
  border-bottom: 5px solid $dc-grey-light;
}

.content--full-width {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.content--left {
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.content--right {
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-left: 5px solid $dc-grey-light;
}

.content--hidden {
  display: none;
}

.content--bottom {
  position: absolute;
  height: 40%;
  bottom: 0;
  left: 0;
  right: 0;
}

.content--tab {
  overflow: hidden;
  padding: 0px;
  list-style: none;

  li a {
    @extend .no-link-style;
    color: rgba(86, 94, 101, 0.5);
    height: 30px;
    font-size: $dc-font-size-small;
    line-height: 30px;
    padding: 0px 12px;
    border: none;
    display: block;
    float: left;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.expand {
      padding-left: 0px;
    }
  }

  li a.active {
    z-index: 1;
    background-color: white;
    color: $dc-blue-text;
    font-weight: $dc-font-weight-regular;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  li:first-child a.active {
    border-left: none;
  }
}

// Override golden layout tabs display
.lm_header {
  .lm_tab,
  .lm_tab.lm_active {
    position: relative;

    > .lm_close_tab {
      background-image: none;
      top: 5px;

      &:after {
        content: '\2716'; // thick `x` icon after tab/file name in tabs
        color: $dc-white;
        width: 16px;
        height: 16px;
        opacity: 0.8;
      }
    }
  }
}

.theme--light {
  .lm_header {
    .lm_tab {
      > .lm_close_tab {
        &:after {
          color: #333;
        }
      }

      &.lm_active {
        > .lm_close_tab {
          &:after {
            color: $dc-white;
          }
        }
      }
    }
  }
}

.content--tab-body {
  position: absolute;
  top: 30px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
}

.content--buttons-top {
  padding-top: 35px;
  .content--tab-buttons {
    top: 0;
    bottom: auto;
  }
}

.content--tab-buttons {
  display: flex;
  align-items: center;
  background: linear-gradient(
      0deg,
      $dc-navy-dark 0%,
      #05192dcc 25%,
      #05192d00 50%,
      #05192d00 100%
    );
  bottom: 0;
  height: 64px;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 0 spacing(2);
  position: absolute;
  right: 0;
  pointer-events: none;
}

.content--tab-buttons-left,
.content--tab-buttons-right {
  justify-content: center;
  display: flex;
}

.exercise--reset {
  color: $dc-white;
  margin-right: 0;

  &:active,
  &:focus,
  &:hover {
    color: $dc-white;
  }
}

.exercise--reset .fa {
  margin: 0;
}

.gl-content .jqconsole {
  border-radius: 0 0 $dc-border-radius $dc-border-radius !important;
  background-color: white;
  border: none;
}

.code-editor {
  background: $dc-navy;
  height: 100%;
  width: 100%;
  position: inherit; // required for z-index to work in onboarding
  overflow: hidden;
  border-bottom-left-radius: $dc-border-radius;
}

.editor-spinner {
  @extend .global-spinner;
  width: 7rem;
}

.close-tab {
  margin-left: -10px;
  cursor: pointer;
  padding: 0px;
}
