/* Studio-Feixen-Sans Regular */

@font-face {
  font-family: 'Studio-Feixen-Sans';
  src: url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-latin-v2.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-latin-v2.woff')
    format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  unicode-range: U+00A1, U+00AA-00AB, U+00AF, U+00B8, U+00BB, U+00BF-00D6,
    U+00D8-00F6, U+00F8-00FF, U+0131, U+0152-0153, U+02B0-02FF;
}

@font-face {
  font-family: 'Studio-Feixen-Sans';
  src: url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-latin-extended-v2.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-latin-extended-v2.woff')
    format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0100-0130, U+0132-0151, U+0154-017F, U+0180-024F, U+1E00-1EFF;
}

@font-face {
  font-family: 'Studio-Feixen-Sans';
  src: url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-rest-v2.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-rest-v2.woff')
    format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0259, U+0300-03C0, U+2070-2073, U+2075-20AB, U+20AD-2121,
    U+2123-218F, U+21BC-2211, U+2213-2214, U+2216-F8FE, U+FB01-FB02;
}

@font-face {
  font-family: 'Studio-Feixen-Sans';
  src: url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-english-v2.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSansRegular-english-v2.woff')
    format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0000-00A0, U+00A2-00A9, U+00AC-00AE, U+00B0-00B7, U+00B9-00BA,
    U+00BC-00BE, U+00D7, U+00F7, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2190-21BB, U+2212, U+2215, U+F8FF, U+FEFF, U+FFFD;
}

/* Studio-Feixen-Sans Bold */

@font-face {
  font-family: 'Studio-Feixen-Sans';
  src: url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSans-Semibold-latin.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSans-Semibold-latin.woff')
    format('woff');
  font-display: swap;
  font-weight: 800;
  font-style: normal;
  unicode-range: U+00A1, U+00AA-00AB, U+00AF, U+00B8, U+00BB, U+00BF-00D6,
    U+00D8-00F6, U+00F8-00FF, U+0131, U+0152-0153, U+02B0-02FF;
}

@font-face {
  font-family: 'Studio-Feixen-Sans';
  src: url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSans-Semibold-latin-extended.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSans-Semibold-latin-extended.woff')
    format('woff');
  font-display: swap;
  font-weight: 800;
  font-style: normal;
  unicode-range: U+0100-0130, U+0132-0151, U+0154-017F, U+0180-024F, U+1E00-1EFF;
}

@font-face {
  font-family: 'Studio-Feixen-Sans';
  src: url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSans-Semibold-rest.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSans-Semibold-rest.woff')
    format('woff');
  font-display: swap;
  font-weight: 800;
  font-style: normal;
  unicode-range: U+0259, U+0300-03C0, U+2070-2073, U+2075-20AB, U+20AD-2121,
    U+2123-218F, U+21BC-2211, U+2213-2214, U+2216-F8FE, U+FB01-FB02;
}

@font-face {
  font-family: 'Studio-Feixen-Sans';
  src: url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSans-Semibold-english.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/StudioFeixenSans-Semibold-english.woff')
    format('woff');
  font-display: swap;
  font-weight: 800;
  font-style: normal;
  unicode-range: U+0000-00A0, U+00A2-00A9, U+00AC-00AE, U+00B0-00B7, U+00B9-00BA,
    U+00BC-00BE, U+00D7, U+00F7, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2190-21BB, U+2212, U+2215, U+F8FF, U+FEFF, U+FFFD;
}

/* JetBrainsMonoNL */

@font-face {
  font-family: 'JetBrainsMonoNL';
  src: url('https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-latin.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-latin.woff')
    format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  unicode-range: U+00A1, U+00AA-00AB, U+00AF, U+00B8, U+00BB, U+00BF-00D6,
    U+00D8-00F6, U+00F8-00FF, U+0131, U+0152-0153, U+02B0-02FF;
}

@font-face {
  font-family: 'JetBrainsMonoNL';
  src: url('https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-latin-extended.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-latin-extended.woff')
    format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0100-0130, U+0132-0151, U+0154-017F, U+0180-024F, U+1E00-1EFF;
}

@font-face {
  font-family: 'JetBrainsMonoNL';
  src: url('https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-rest.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-rest.woff')
    format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0259, U+0300-03C0, U+2070-2073, U+2075-20AB, U+20AD-2121,
    U+2123-218F, U+21BC-2211, U+2213-2214, U+2216-F8FE, U+FB01-FB02;
}

@font-face {
  font-family: 'JetBrainsMonoNL';
  src: url('https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-english.woff2')
    format('woff2'),
    url('https://dcmfe.datacamp.com/assets/fonts/JetBrainsMono-english.woff')
    format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0000-00A0, U+00A2-00A9, U+00AC-00AE, U+00B0-00B7, U+00B9-00BA,
    U+00BC-00BE, U+00D7, U+00F7, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2190-21BB, U+2212, U+2215, U+F8FF, U+FEFF, U+FFFD;
}
