@-webkit-keyframes boingInUp {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(-90deg);
  }
  
  50% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(50deg);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(0deg);
  }
}
@keyframes boingInUp {
  0% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: perspective(800px) rotateX(-90deg);
  }
  
  50% {
    opacity: 1;
    transform-origin: 50% 0%;
    transform: perspective(800px) rotateX(50deg);
  }

  100% {
    opacity: 1;
    transform-origin: 50% 0%;
    transform: perspective(800px) rotateX(0deg);
  }
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

@keyframes shake {
  0% { transform: translate(2px, 1px) rotate(0deg); }
  20% { transform: translate(-1px, -2px) rotate(-2deg); }
  40% { transform: translate(-2px, 0px) rotate(2deg); }
  60% { transform: translate(0px, 2px) rotate(0deg); }
  80% { transform: translate(1px, -1px) rotate(1deg); }
  100% { transform: translate(-1px, 1px) rotate(-1deg); }
}

@-webkit-keyframes shake {
  0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
  20% { -webkit-transform: translate(-1px, -2px) rotate(-2deg); }
  40% { -webkit-transform: translate(-2px, 0px) rotate(2deg); }
  60% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
  80% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
  100% { -webkit-transform: translate(-1px, 1px) rotate(-1deg); }
}

.animation--flash {
  -webkit-animation: flash 1s ease-out;
  animation: flash 1s ease-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.animation--boingInUp {
  -webkit-animation: boingInUp 0.5s ease-out;
  animation: boingInUp 0.5s ease-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.animation--shake {
  -webkit-animation: shake 0.3s ease-out;
  animation: shake 0.3s ease-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}