.exercise-area {
  position: absolute;
  top: spacing(10);
  bottom: spacing(2);
  right: spacing(2);
  left: spacing(2);
  overflow: hidden;
}

.progress-indicator--visible > .exercise-area {
  bottom: 32px;
}

.exercise-waiting {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.global-spinner {
  z-index: 1;
  width: 10rem;
}

.exercise--typography {
  background: $dc-white;
  color: $dc-navy-text;
}
