/**
 * Utility classes, all use !important so be careful when chaining utilities
 * which will compete with each other (last in list takes precedence).
 *
 * Note: any types lists with @bp include responsive utilities.
 */

/**
 * Table of contents
 * =================
 * - Background
 *   - background (.dc-u-bg)
 *   - background-color (.dc-u-bgc)
 * - Border
 *   - border (.dc-u-b)
 *   - border-bottom/left/right/top (.dc-u-b[b/l/r/t])
 *   - border-color (.dc-u-bc)
 * - Border radius
 *   - border-radius (.dc-u-brad)
 * - Box shadow
 *   - box-shadow (.dc-u-bs)
 * - Color
 *   - color (.dc-u-color)
 * - Cursor
 *   - cursor (.dc-u-cursor-)
 * - Display
 *   - display (.dc-u-d)@bp
 * - Flexbox
 *   - display flex (.dc-u-fx)@bp
 *   - display inline-flex (.dc-u-ifx)@bp
 *   - Flex container (.dc-u-fx)
 *     - flex direction (.dc-u-fx-d)@bp
 *     - flex wrap (.dc-u-fx-w)
 *     - align-content (.dc-u-fx-ac)@bp
 *     - align-items (.dc-u-fx-ai)@bp
 *     - justify-content (.dc-u-fx-jc)@bp
 *   - Flex item (.dc-u-fxi)
 *     - flex (.dc-u-fxi-f)
 *     - flex-basis (.dc-u-fxi-fb)
 *     - flex-grow (.dc-u-fxi-fg)
 *     - flex-shrink (.dc-u-fxi-fs)
 *     - align-self (.dc-u-fxi-as)@bp
 *     - order (.dc-u-fxi-o)
 * - Font
 *   - font-family (.dc-u-ff)
 *   - font-size (.dc-u-fs)@bp
 *   - font-style (.dc-u-fst)
 *   - font-weight (.dc-u-fw)
 * - Height
 *   - height (.dc-u-h)
 *   - max-height (.dc-u-maxh)
 *   - min-height (.dc-u-minh)
 * - Letter spacing
 *   - letter-spacing (.dc-u-ls)
 * - Line height
 *   - line-height (.dc-u-lh)
 * - List
 *   - list-style-type (.dc-u-lst)
 * - Margin
 *   - margin (.dc-u-m)
 *   - margin-bottom/left/right/top (.dc-u-m[b/l/r/t])
 *   - margin horizontal (.dc-u-mh)
 *   - margin vertical (.dc-u-mv)
 * - Opacity
 *   - opacity (.dc-u-op)
 * - Overflow
 *   - overflow (.dc-u-of)
 *   - overflow-x (.dc-u-ofx)
 *   - overflow-y (.dc-u-ofy)
 * - Padding
 *   - padding (.dc-u-p)
 *   - padding-bottom/left/right/top (.dc-u-p[b/l/r/t])
 *   - padding horizontal (.dc-u-ph])
 *   - padding vertical (.dc-u-pv])
 * - Position
 *   - position (.dc-u-pos)
 *   - stretch (.dc-u-stretch)
 *   - bottom, left, right, top (.dc-u-{bottom/left/right/top})
 * - Table
 *   - table-layout (.dc-u-tbl-l)
 * - Text
 *   - text (.dc-u-t)
 *   - text-align (.dc-u-ta)@bp
 *   - text-decoration (.dc-u-td)
 *   - text-orientation (.dc-u-tor)
 *   - text-overflow (.dc-u-tof)
 *   - text-transform (.dc-u-tt)
 * - Transform
 *   - rotate (.dc-u-trfm-rot)
 * - Transition
 *   - transition (.dc-u-trsn)
 * - Vertical align
 *   - vertical-align (.dc-u-va)
 * - Visibility
 *   - visibility (.dc-u-v)
 * - White space
 *   - white-space (.dc-u-ws)
 * - Width
 *   - width (.dc-u-w)
 *   - max-width (.dc-u-maxw)
 *   - min-width (.dc-u-minw)
 * - Width x Height
 *   - width x height (.dc-u-wh)
 * - Word break
 *   - word-break
 * - z-index
 *   - z-index (.dc-u-z) 0/10/20/30/40/50/60/70/80/90/100/999
 */

// convention for using none or 0: if the property being set is a shorthand
// use none, but if the property being set is a value then use 0.

/**
 * Background
 * ----------
 * Base: bg = background (.dc-u-bg)
 *
 * Modifiers:
 *   bgc = background-color (.dc-u-bgc)
 */

.dc-u-bg-none {
  background: 0 !important;
}

// background-color (.dc-u-bgc)
@each $color, $hexcode in $dc-colors {
  .dc-u-bgc-#{$color} {
    background-color: #{$hexcode} !important;
  }
}

/**
   * Border
   * ------
   * Base: b = border (.dc-u-b)
   *
   * Modifiers:
   *   bb = border-bottom (.dc-u-bb)
   *   bl = border-left (.dc-u-bl)
   *   br = border-right (.dc-u-br)
   *   bt = border-top (.dc-u-bt)
   *   bc = border-color (.dc-u-bc)
   */

.dc-u-b {
  border: 1px solid $dc-grey-100 !important;
}

// Additive: border-bottom/left/right/top (.dc-u-b[b/l/r/t])

@each $side, $value in $dc-sides {
  .dc-u-b#{$side} {
    border-#{$value}: 1px solid $dc-grey-100 !important;
  }
}

// Subtractive: border-bottom/left/right/top (.dc-u-b[b/l/r/t])

.dc-u-b-none {
  border: 0 !important;
}

.dc-u-bb-none {
  border-bottom: 0 !important;
}

.dc-u-bl-none {
  border-left: 0 !important;
}

.dc-u-br-none {
  border-right: 0 !important;
}

.dc-u-bt-none {
  border-top: 0 !important;
}

// border-color (.dc-u-bc)
@each $color, $hexcode in $dc-colors {
  .dc-u-bc-#{$color} {
    border-color: #{$hexcode} !important;
  }
}

/**
   * Border radius
   * -------------
   * Base: brad = border-radius (.dc-u-brad)
   *
   * Modifiers:
   *   all (.dc-u-brad-all)
   *   bl = bottom left (.dc-u-brad-bl)
   *   br = bottom right (.dc-u-brad-br)
   *   tl = top left (.dc-u-brad-tl)
   *   tr = top right (.dc-u-brad-tr)
   *   b = bottom left and bottom right (.dc-u-brad-bottom)
   *   t = top left and top right (.dc-u-brad-top)
   *   circle (.dc-u-brad-circle)
   *   none (.dc-u-brad-none)
   */

.dc-u-brad-all {
  border-radius: $dc-border-radius !important;
}

.dc-u-brad-bl {
  border-bottom-left-radius: $dc-border-radius !important;
}

.dc-u-brad-br {
  border-bottom-right-radius: $dc-border-radius !important;
}

.dc-u-brad-tl {
  border-top-left-radius: $dc-border-radius !important;
}

.dc-u-brad-tr {
  border-top-right-radius: $dc-border-radius !important;
}

.dc-u-brad-b {
  border-bottom-left-radius: $dc-border-radius !important;
  border-bottom-right-radius: $dc-border-radius !important;
}

.dc-u-brad-t {
  border-top-left-radius: $dc-border-radius !important;
  border-top-right-radius: $dc-border-radius !important;
}

.dc-u-brad-circle {
  border-radius: 50% !important;
}

.dc-u-brad-none {
  border-radius: 0 !important;
}

.dc-u-brad-bl-0 {
  border-bottom-left-radius: 0 !important;
}

.dc-u-brad-br-0 {
  border-bottom-right-radius: 0 !important;
}

.dc-u-brad-tl-0 {
  border-top-left-radius: 0 !important;
}

.dc-u-brad-tr-0 {
  border-top-right-radius: 0 !important;
}

.dc-u-brad-b-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.dc-u-brad-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/** Box shadow */
.dc-u-bs-border {
  box-shadow: $dc-box-shadow-border !important;
}

.dc-u-bs-sm {
  box-shadow: $dc-box-shadow-sm !important;
}

.dc-u-bs-md {
  box-shadow: $dc-box-shadow-md !important;
}

.dc-u-bs-lg {
  box-shadow: $dc-box-shadow-lg !important;
}

.dc-u-bs-xl {
  box-shadow: $dc-box-shadow-xl !important;
}

.dc-u-bs-xxl {
  box-shadow: $dc-box-shadow-xxl !important;
}

.dc-u-bs-xl-green {
  box-shadow: $dc-box-shadow-xl-green !important;
}

.dc-u-bs-xl-orange {
  box-shadow: $dc-box-shadow-xl-orange !important;
}

.dc-u-bs-xl-purple {
  box-shadow: $dc-box-shadow-xl-purple !important;
}

.dc-u-bs-none {
  box-shadow: none !important;
}

/** Color */
@each $color, $hexcode in $dc-colors {
  .dc-u-color-#{$color} {
    color: #{$hexcode} !important;
  }
}

/** Cursor */
.dc-u-cursor-auto {
  cursor: auto !important;
}

.dc-u-cursor-default {
  cursor: auto !important;
}

.dc-u-cursor-grab {
  cursor: grab !important;
}

.dc-u-cursor-help {
  cursor: help !important;
}

.dc-u-cursor-move {
  cursor: move !important;
}

.dc-u-cursor-pointer {
  cursor: pointer !important;
}

.dc-u-cursor-text {
  cursor: text !important;
}

.dc-u-cursor-not-allowed {
  cursor: not-allowed !important;
}

/**
   * Display
   * -------
   * Base: d = display (.dc-u-d)
   *
   * Modifiers:
   *   b = block (.dc-u-d-b)
   *   i = inline (.dc-u-d-i)
   *   ib = inline-block (.dc-u-d-ib)
   *   tbl = table (.dc-u-d-tbl)
   *   tbl-cell = table cell (.dc-u-d-tbl-cell)
   *   none (.dc-u-d-none)
   */

// Note: Flex display utilities are in the Flexbox section (.dc-u-fx)

.dc-u-d-b {
  display: block !important;
}

.dc-u-d-i {
  display: inline !important;
}

.dc-u-d-ib {
  display: inline-block !important;
}

.dc-u-d-none {
  display: none !important;
}

.dc-u-d-tbl {
  display: table !important;
}

.dc-u-d-tbl-cell {
  display: table-cell !important;
}

.dc-u-fx {
  display: flex !important;
}

.dc-u-ifx {
  display: inline-flex !important;
}

@each $size, $bp in $dc-breakpoints {
  // responsive display utilities
  @media screen and (min-width: $bp) {
    .dc-u-d-b\@#{$size} {
      display: block !important;
    }

    .dc-u-d-i\@#{$size} {
      display: inline !important;
    }

    .dc-u-d-ib\@#{$size} {
      display: inline-block !important;
    }

    .dc-u-d-tbl\@#{$size} {
      display: table !important;
    }

    .dc-u-d-tbl-cell\@#{$size} {
      display: table-cell !important;
    }

    .dc-u-d-none\@#{$size} {
      display: none !important;
    }
  }
}

/** Fill */
@each $color, $hexcode in $dc-colors {
  .dc-u-fill-#{$color} {
    fill: #{$hexcode} !important;
  }
}

/**
   * Flexbox
   * -------
   * Flex container base: fx = flex (.dc-u-fx)
   *
   * Flex container modifiers:
   *   fd = flex direction (.dc-u-fx-fd)
   *   fw = flex wrap (.dc-u-fx-fw)
   *   ac = align-content (.dc-u-fx-ac)
   *   ai = align-items (.dc-u-fx-ai)
   *   jc = justify-content (.dc-u-fx-jc)
   *   center = vertically and horizontally centered (.dc-u-fx-center)
   *
   * Flex item base: fxi = flex item (.dc-u-fxi)
   *
   * Flex item modifiers:
   *   f = flex (.dc-u-fxi-f)
   *   fb = flex-basis (.dc-u-fxi-fb)
   *   fg = flex-grow (.dc-u-fxi-fg)
   *   fs = flex-shrink (.dc-u-fxi-fs)
   *   as = align-self (.dc-u-fxi-as)
   *   o = order (.dc-u-fxi-o)
   */

@each $size, $bp in $dc-breakpoints {
  // responsive display flex utilities
  @media screen and (min-width: $bp) {
    .dc-u-fx\@#{$size} {
      display: flex !important;
    }

    .dc-u-ifx\@#{$size} {
      display: inline-flex !important;
    }
  }
}

/** Flex containers */

.dc-u-fx-center {
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

// flex-direction (.dc-u-fx-fd)
.dc-u-fx-fdc {
  flex-direction: column !important;
}

.dc-u-fx-fdcr {
  flex-direction: column-reverse !important;
}

.dc-u-fx-fdr {
  flex-direction: row !important;
}

.dc-u-fx-fdrr {
  flex-direction: row-reverse !important;
}

@each $size, $bp in $dc-breakpoints {
  // responsive flex direction utilities
  @media screen and (min-width: $bp) {
    .dc-u-fx-fdc\@#{$size} {
      flex-direction: column !important;
    }

    .dc-u-fx-fdcr\@#{$size} {
      flex-direction: column-reverse !important;
    }

    .dc-u-fx-fdr\@#{$size} {
      flex-direction: row !important;
    }

    .dc-u-fx-fdrr\@#{$size} {
      flex-direction: row-reverse !important;
    }
  }
}

// flex-wrap (.dc-u-fx-fw)
.dc-u-fx-fwnw {
  flex-wrap: nowrap !important;
}

.dc-u-fx-fww {
  flex-wrap: wrap !important;
}

.dc-u-fx-fwwr {
  flex-wrap: wrap-reverse !important;
}

// Flex align-content (.dc-u-fx-ac)
.dc-u-fx-acc {
  align-content: center !important;
}

.dc-u-fx-acfe {
  align-content: flex-end !important;
}

.dc-u-fx-acfs {
  align-content: flex-start !important;
}

.dc-u-fx-acsa {
  align-content: space-around !important;
}

.dc-u-fx-acsb {
  align-content: space-between !important;
}

.dc-u-fx-acs {
  align-content: stretch !important;
}

@each $size, $bp in $dc-breakpoints {
  // responsive flex align-content utilities
  @media screen and (min-width: $bp) {
    .dc-u-fx-acc\@#{$size} {
      align-content: center !important;
    }

    .dc-u-fx-acfe\@#{$size} {
      align-content: flex-end !important;
    }

    .dc-u-fx-acfs\@#{$size} {
      align-content: flex-start !important;
    }

    .dc-u-fx-acsa\@#{$size} {
      align-content: space-around !important;
    }

    .dc-u-fx-acsb\@#{$size} {
      align-content: space-between !important;
    }

    .dc-u-fx-acs\@#{$size} {
      align-content: stretch !important;
    }
  }
}

// Flex align-items (.dc-u-fx-ai)
.dc-u-fx-aib {
  align-items: baseline !important;
}

.dc-u-fx-aic {
  align-items: center !important;
}

.dc-u-fx-aife {
  align-items: flex-end !important;
}

.dc-u-fx-aifs {
  align-items: flex-start !important;
}

.dc-u-fx-ais {
  align-items: stretch !important;
}

@each $size, $bp in $dc-breakpoints {
  // responsive flex align-items utilities
  @media screen and (min-width: $bp) {
    .dc-u-fx-aib\@#{$size} {
      align-items: baseline !important;
    }

    .dc-u-fx-aic\@#{$size} {
      align-items: center !important;
    }

    .dc-u-fx-aife\@#{$size} {
      align-items: flex-end !important;
    }

    .dc-u-fx-aifs\@#{$size} {
      align-items: flex-start !important;
    }

    .dc-u-fx-ais\@#{$size} {
      align-items: stretch !important;
    }
  }
}

// Flex justify-content (.dc-u-fx-jc)
// note: 'space-evenly' not supported by IE11.
.dc-u-fx-jcc {
  justify-content: center !important;
}

.dc-u-fx-jcfe {
  justify-content: flex-end !important;
}

.dc-u-fx-jcfs {
  justify-content: flex-start !important;
}

.dc-u-fx-jcsa {
  justify-content: space-around !important;
}

.dc-u-fx-jcsb {
  justify-content: space-between !important;
}

@each $size, $bp in $dc-breakpoints {
  // responsive flex justify-content utilities
  @media screen and (min-width: $bp) {
    .dc-u-fx-jcc\@#{$size} {
      justify-content: center !important;
    }

    .dc-u-fx-jcfe\@#{$size} {
      justify-content: flex-end !important;
    }

    .dc-u-fx-jcfs\@#{$size} {
      justify-content: flex-start !important;
    }

    .dc-u-fx-jcsa\@#{$size} {
      justify-content: space-around !important;
    }

    .dc-u-fx-jcsb\@#{$size} {
      justify-content: space-between !important;
    }
  }
}

/** Flex item */

// flex (.dc-u-fxi-f)
.dc-u-fxi-f-0 {
  flex: 0 auto !important;
}

.dc-u-fxi-f-1 {
  flex: 1 !important;
}

.dc-u-fxi-f-auto {
  flex: auto !important;
}

.dc-u-fxi-f-none {
  flex: none !important;
}

// flex-basis (.dc-u-fxi-fb)
// note: 'content' not well supported yet.

@each $size in $dc-spatial-all {
  .dc-u-fxi-fb-#{$size} {
    flex-basis: #{$size}px !important;
  }
}

@each $percentage in $dc-percentages {
  .dc-u-fxi-fb-#{$percentage}pc {
    flex-basis: $percentage * 1% !important;
  }
}

.dc-u-fxi-fb-auto {
  flex-basis: auto !important;
}

// flex-grow (.dc-u-fxi-fg)
.dc-u-fxi-fg-0 {
  flex-grow: 0 !important;
}

.dc-u-fxi-fg-1 {
  flex-grow: 1 !important;
}

// flex-shrink (.dc-u-fxi-fs)
.dc-u-fxi-fs-0 {
  flex-shrink: 0 !important;
}

.dc-u-fxi-fs-1 {
  flex-shrink: 1 !important;
}

// Flex align-self (.dc-u-fxi-as)
.dc-u-fxi-asa {
  align-self: auto !important;
}

.dc-u-fxi-asb {
  align-self: baseline !important;
}

.dc-u-fxi-asc {
  align-self: center !important;
}

.dc-u-fxi-asfe {
  align-self: flex-end !important;
}

.dc-u-fxi-asfs {
  align-self: flex-start !important;
}

.dc-u-fxi-ass {
  align-self: stretch !important;
}

@each $size, $bp in $dc-breakpoints {
  // responsive flex item align-self utilities
  @media screen and (min-width: $bp) {
    .dc-u-fxi-asa\@#{$size} {
      align-self: auto !important;
    }

    .dc-u-fxi-asb\@#{$size} {
      align-self: baseline !important;
    }

    .dc-u-fxi-asc\@#{$size} {
      align-self: center !important;
    }

    .dc-u-fxi-asfe\@#{$size} {
      align-self: flex-end !important;
    }

    .dc-u-fxi-asfs\@#{$size} {
      align-self: flex-start !important;
    }

    .dc-u-fxi-ass\@#{$size} {
      align-self: stretch !important;
    }
  }
}

// Flex order (.dc-u-fxi-o)
.dc-u-fxi-o-0 {
  order: 0 !important;
}

.dc-u-fxi-o-1 {
  order: 1 !important;
}

/** Font */

// font-family
.dc-u-ff-sans-serif {
  font-family: $dc-font-family-sans-serif !important;
}

.dc-u-ff-mono {
  font-family: $dc-font-family-mono !important;
}

// font-size
.dc-u-fs-base {
  font-size: $dc-font-size-base !important;
}

.dc-u-fs-micro {
  font-size: $dc-font-size-micro !important;
}

.dc-u-fs-small {
  font-size: $dc-font-size-small !important;
}

.dc-u-fs-h1 {
  font-size: $dc-font-size-h1 !important;
}

.dc-u-fs-h2 {
  font-size: $dc-font-size-h2 !important;
}

.dc-u-fs-h3 {
  font-size: $dc-font-size-h3 !important;
}

.dc-u-fs-h4 {
  font-size: $dc-font-size-h4 !important;
}

.dc-u-fs-h5 {
  font-size: $dc-font-size-h5 !important;
}

.dc-u-fs-h6 {
  font-size: $dc-font-size-h6 !important;
}

@each $size, $bp in $dc-breakpoints {
  // responsive font-size utilities
  @media screen and (min-width: $bp) {
    .dc-u-fs-base\@#{$size} {
      font-size: $dc-font-size-base !important;
    }

    .dc-u-fs-micro\@#{$size} {
      font-size: $dc-font-size-micro !important;
    }

    .dc-u-fs-small\@#{$size} {
      font-size: $dc-font-size-small !important;
    }

    .dc-u-fs-h1\@#{$size} {
      font-size: $dc-font-size-h1 !important;
    }

    .dc-u-fs-h2\@#{$size} {
      font-size: $dc-font-size-h2 !important;
    }

    .dc-u-fs-h3\@#{$size} {
      font-size: $dc-font-size-h3 !important;
    }

    .dc-u-fs-h4\@#{$size} {
      font-size: $dc-font-size-h4 !important;
    }

    .dc-u-fs-h5\@#{$size} {
      font-size: $dc-font-size-h5 !important;
    }

    .dc-u-fs-h6\@#{$size} {
      font-size: $dc-font-size-h6 !important;
    }
  }
}

// font-style
.dc-u-fst-italic {
  font-style: italic !important;
}

.dc-u-fst-normal {
  font-style: normal !important;
}

// font-weight
.dc-u-fw-light {
  font-weight: $dc-font-weight-light !important;
}

.dc-u-fw-regular {
  font-weight: $dc-font-weight-regular !important;
}

.dc-u-fw-bold {
  font-weight: $dc-font-weight-bold !important;
}

/** Height */
.dc-u-h-0 {
  height: 0 !important;
}

@each $size in $dc-spatial-all {
  .dc-u-h-#{$size} {
    height: #{$size}px !important;
  }
}

// percentage
.dc-u-h-25pc {
  height: 25% !important;
}

.dc-u-h-50pc {
  height: 50% !important;
}

.dc-u-h-75pc {
  height: 75% !important;
}

.dc-u-h-100pc {
  height: 100% !important;
}

// vh
.dc-u-h-25vh {
  height: 25vh !important;
}

.dc-u-h-50vh {
  height: 50vh !important;
}

.dc-u-h-75vh {
  height: 75vh !important;
}

.dc-u-h-100vh {
  height: 100vh !important;
}

.dc-u-h-auto {
  height: auto !important;
}

// max-height
.dc-u-maxh-0 {
  max-height: 0 !important;
}

@each $measure in $dc-measure {
  .dc-u-maxh-#{$measure} {
    max-height: #{$measure}px !important;
  }
}

.dc-u-maxh-25pc {
  max-height: 25% !important;
}

.dc-u-maxh-50pc {
  max-height: 50% !important;
}

.dc-u-maxh-75pc {
  max-height: 75% !important;
}

.dc-u-maxh-100pc {
  max-height: 100% !important;
}

.dc-u-maxh-auto {
  max-height: auto !important;
}

// min-height
.dc-u-minh-0 {
  min-height: 0 !important;
}

.dc-u-minh-128 {
  min-height: 128px !important;
}

@each $measure in $dc-measure {
  .dc-u-minh-#{$measure} {
    min-height: #{$measure}px !important;
  }
}

.dc-u-minh-25pc {
  min-height: 25% !important;
}

.dc-u-minh-50pc {
  min-height: 50% !important;
}

.dc-u-minh-75pc {
  min-height: 75% !important;
}

.dc-u-minh-100pc {
  min-height: 100% !important;
}

.dc-u-minh-auto {
  min-height: auto !important;
}

/** Letter spacing */
.dc-u-ls-0 {
  letter-spacing: 0 !important;
}

.dc-u-ls-1 {
  letter-spacing: 1px !important;
}

.dc-u-ls-2 {
  letter-spacing: 2px !important;
}

.dc-u-ls-base {
  letter-spacing: $dc-letter-spacing-base !important;
}

.dc-u-ls-medium-heading {
  letter-spacing: $dc-letter-spacing-medium-heading !important;
}

.dc-u-ls-large-heading {
  letter-spacing: $dc-letter-spacing-large-heading !important;
}

/** Line height */
.dc-u-lh-1 {
  line-height: 1 !important;
}

.dc-u-lh-base {
  line-height: $dc-line-height-base !important;
}

.dc-u-lh-heading {
  line-height: $dc-line-height-heading !important;
}

.dc-u-lh-large-heading {
  line-height: $dc-line-height-large-heading !important;
}

/** List */

.dc-u-lst-unstyled {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

// list-style-type
.dc-u-lst-circle {
  list-style-type: circle !important;
}

.dc-u-lst-decimal {
  list-style-type: decimal !important;
}

.dc-u-lst-dlz {
  list-style-type: decimal-leading-zero !important;
}

.dc-u-lst-disc {
  list-style-type: disc !important;
}

.dc-u-lst-latin-lower {
  list-style-type: lower-latin !important;
}

.dc-u-lst-latin-upper {
  list-style-type: upper-latin !important;
}

.dc-u-lst-roman-lower {
  list-style-type: lower-roman !important;
}

.dc-u-lst-roman-upper {
  list-style-type: upper-roman !important;
}

.dc-u-lst-square {
  list-style-type: disc !important;
}

.dc-u-lst-none {
  list-style-type: none !important;
}

/** Margin */
.dc-u-m-none {
  margin: 0 !important;
}

.dc-u-m-auto {
  margin: auto !important;
}

// margin bottom/left/right/top
.dc-u-mb-0 {
  margin-bottom: 0 !important;
}

.dc-u-ml-0 {
  margin-left: 0 !important;
}

.dc-u-mr-0 {
  margin-right: 0 !important;
}

.dc-u-mt-0 {
  margin-top: 0 !important;
}

@each $size in $dc-spatial {
  .dc-u-m-#{$size} {
    margin: #{$size}px !important;
  }

  .dc-u-mb-#{$size} {
    margin-bottom: #{$size}px !important;
  }

  .dc-u-ml-#{$size} {
    margin-left: #{$size}px !important;
  }

  .dc-u-mr-#{$size} {
    margin-right: #{$size}px !important;
  }

  .dc-u-mt-#{$size} {
    margin-top: #{$size}px !important;
  }
}

.dc-u-mb-auto {
  margin-bottom: auto !important;
}

.dc-u-ml-auto {
  margin-left: auto !important;
}

.dc-u-mr-auto {
  margin-right: auto !important;
}

.dc-u-mt-auto {
  margin-top: auto !important;
}

// margin horizontal
.dc-u-mh-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@each $size in $dc-spatial {
  .dc-u-mh-#{$size} {
    margin-left: #{$size}px !important;
    margin-right: #{$size}px !important;
  }
}

.dc-u-mh-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

// margin vertical
.dc-u-mv-none {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

@each $size in $dc-spatial {
  .dc-u-mv-#{$size} {
    margin-bottom: #{$size}px !important;
    margin-top: #{$size}px !important;
  }
}

.dc-u-mv-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

/** Opacity */
.dc-u-op-0 {
  opacity: 0 !important;
}

.dc-u-op-1 {
  opacity: 1 !important;
}

/** Overflow */
// TODO: is this better or worse than manual listing? Not sure.
$dc-overflow-types: 'auto', 'hidden', 'scroll', 'visible';

@each $type in $dc-overflow-types {
  .dc-u-of-#{$type} {
    overflow: #{$type} !important;
  }
}

// overflow-x
@each $type in $dc-overflow-types {
  .dc-u-ofx-#{$type} {
    overflow-x: #{$type} !important;
  }
}

// overflow-y
@each $type in $dc-overflow-types {
  .dc-u-ofy-#{$type} {
    overflow-y: #{$type} !important;
  }
}

/** Padding */
.dc-u-p-none {
  padding: 0 !important;
}

// padding-bottom/left/right/top
.dc-u-pb-0 {
  padding-bottom: 0 !important;
}

.dc-u-pl-0 {
  padding-left: 0 !important;
}

.dc-u-pr-0 {
  padding-right: 0 !important;
}

.dc-u-pt-0 {
  padding-top: 0 !important;
}

@each $size in $dc-spatial {
  .dc-u-p-#{$size} {
    padding: #{$size}px !important;
  }

  .dc-u-pb-#{$size} {
    padding-bottom: #{$size}px !important;
  }

  .dc-u-pl-#{$size} {
    padding-left: #{$size}px !important;
  }

  .dc-u-pr-#{$size} {
    padding-right: #{$size}px !important;
  }

  .dc-u-pt-#{$size} {
    padding-top: #{$size}px !important;
  }
}

/**
   * If we want to output all responsive classes, it's around 32kb/22kb minified
   */

@each $name, $value in $dc-breakpoints {
  @media screen and (min-width: $value) {
    @each $size in $dc-spatial-common {
      .dc-u-p-#{$size}\@#{$name} {
        padding: #{$size}px !important;
      }

      // .dc-u-pb-#{$size}\@#{$name} {
      //   padding-bottom: #{$size}px !important;
      // }

      // .dc-u-pl-#{$size}\@#{$name} {
      //   padding-left: #{$size}px !important;
      // }

      // .dc-u-pr-#{$size}\@#{$name} {
      //   padding-right: #{$size}px !important;
      // }

      // .dc-u-pt-#{$size}\@#{$name} {
      //   padding-top: #{$size}px !important;
      // }
    }
  }
}

// padding horizontal
.dc-u-ph-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@each $size in $dc-spatial {
  .dc-u-ph-#{$size} {
    padding-left: #{$size}px !important;
    padding-right: #{$size}px !important;
  }
}

// padding vertical
.dc-u-pv-none {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

@each $size in $dc-spatial {
  .dc-u-pv-#{$size} {
    padding-bottom: #{$size}px !important;
    padding-top: #{$size}px !important;
  }
}

/** Position */
.dc-u-pos-absolute {
  position: absolute !important;
}

.dc-u-pos-fixed {
  position: fixed !important;
}

.dc-u-pos-relative {
  position: relative !important;
}

.dc-u-pos-static {
  position: static !important;
}

// stretch
.dc-u-stretch {
  bottom: 0 !important;
  left: 0 !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}

// bottom, left, right, top
.dc-u-bottom-0 {
  bottom: 0 !important;
}

.dc-u-left-0 {
  left: 0 !important;
}

.dc-u-right-0 {
  right: 0 !important;
}

.dc-u-top-0 {
  top: 0 !important;
}

/** Table */

// table-layout
.dc-u-tbl-l-fixed {
  table-layout: fixed !important;
}

.dc-u-tbl-l-auto {
  table-layout: auto !important;
}

/** Text */
.dc-u-t-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

// text-align
.dc-u-ta-center {
  text-align: center !important;
}

.dc-u-ta-justify {
  text-align: justify !important;
}

.dc-u-ta-left {
  text-align: left !important;
}

.dc-u-ta-right {
  text-align: right !important;
}

@each $size, $bp in $dc-breakpoints {
  // responsive text-align utilities
  @media screen and (min-width: $bp) {
    .dc-u-ta-center\@#{$size} {
      text-align: center !important;
    }

    .dc-u-ta-justify\@#{$size} {
      text-align: justify !important;
    }

    .dc-u-ta-left\@#{$size} {
      text-align: left !important;
    }

    .dc-u-ta-right\@#{$size} {
      text-align: right !important;
    }
  }
}

// text-decoration
.dc-u-td-strike {
  text-decoration: line-through !important;
}

.dc-u-td-underline {
  text-decoration: underline !important;
}

.dc-u-td-none {
  text-decoration: none !important;
}

// text-orientation
.dc-u-tor-mixed {
  text-orientation: mixed !important;
}

.dc-u-tor-sideways {
  text-orientation: sideways !important;
}

.dc-u-tor-upright {
  text-orientation: upright !important;
}

// text-overflow
.dc-u-tof-clip {
  text-overflow: clip !important;
}

.dc-u-tof-ellipsis {
  text-overflow: ellipsis !important;
}

// text-transform
.dc-u-tt-capitalize {
  text-transform: capitalize !important;
}

.dc-u-tt-lowercase {
  text-transform: lowercase !important;
}

.dc-u-tt-uppercase {
  text-transform: uppercase !important;
}

.dc-u-tt-none {
  text-transform: none !important;
}

/** Transform */
.dc-u-trfm-rot-0 {
  transform: rotate(0deg) !important;
}

@each $deg in $dc-angles {
  .dc-u-trfm-rot-#{$deg} {
    transform: rotate(#{$deg}deg) !important;
  }
}

/** Transition */
.dc-u-trsn-none,
.dc-u-trsn-none * {
  transition: all 0s ease 0s !important;

  &::after,
  &::before {
    transition: all 0s ease 0s !important;
  }
}

/** Vertical align */
.dc-u-va-baseline {
  vertical-align: baseline !important;
}

.dc-u-va-bottom {
  vertical-align: bottom !important;
}

.dc-u-va-middle {
  vertical-align: middle !important;
}

.dc-u-va-sub {
  vertical-align: sub !important;
}

.dc-u-va-super {
  vertical-align: super !important;
}

.dc-u-va-text-bottom {
  vertical-align: text-bottom !important;
}

.dc-u-va-text-top {
  vertical-align: text-top !important;
}

.dc-u-va-top {
  vertical-align: top !important;
}

/** Visibility */
.dc-u-v-collapse {
  visibility: collapse !important;
}

.dc-u-v-hidden {
  visibility: hidden !important;
}

.dc-u-v-visible {
  visibility: visible !important;
}

/** White space */
.dc-u-ws-normal {
  white-space: normal !important;
}

.dc-u-ws-nowrap {
  white-space: nowrap !important;
}

.dc-u-ws-pre {
  white-space: pre !important;
}

.dc-u-ws-pre-line {
  white-space: pre-line !important;
}

.dc-u-ws-pre-wrap {
  white-space: pre-wrap !important;
}

/** Width */
.dc-u-w-0 {
  width: 0 !important;
}

@each $size in $dc-spatial-all {
  .dc-u-w-#{$size} {
    width: #{$size}px !important;
  }
}

// percentages
@each $percentage in $dc-percentages {
  .dc-u-w-#{$percentage}pc {
    width: $percentage * 1% !important;
  }
}

.dc-u-w-auto {
  width: auto !important;
}

// max-width
@each $measure in $dc-measure {
  .dc-u-maxw-#{$measure} {
    max-width: #{$measure}px !important;
  }
}

@each $percentage in $dc-percentages {
  .dc-u-maxw-#{$percentage}pc {
    max-width: $percentage * 1% !important;
  }
}

.dc-u-maxw-auto {
  max-width: auto !important;
}

// min-width
.dc-u-minw-128 {
  min-width: 128px !important;
}

@each $measure in $dc-measure {
  .dc-u-minw-#{$measure} {
    min-width: #{$measure}px !important;
  }
}

@each $percentage in $dc-percentages {
  .dc-u-minw-#{$percentage}pc {
    min-width: $percentage * 1% !important;
  }
}

.dc-u-minw-auto {
  min-width: auto !important;
}

.dc-u-minw-0 {
  min-width: 0 !important;
}

/** width x height */

@each $size in $dc-spatial-all {
  .dc-u-wh-#{$size} {
    height: #{$size}px !important;
    width: #{$size}px !important;
  }
}

@each $percentage in $dc-percentages {
  .dc-u-wh-#{$percentage}pc {
    height: $percentage * 1% !important;
    width: $percentage * 1% !important;
  }
}

.dc-u-wh-auto {
  height: auto !important;
  width: auto !important;
}

/** Word break */

.dc-u-wb-normal {
  word-break: normal !important;
}

.dc-u-wb-ba {
  word-break: break-all !important;
}

.dc-u-wb-bw {
  word-break: break-word !important;
}

.dc-u-wb-ka {
  word-break: keep-all !important;
}

/** z-index */
@each $index in $dc-z-indexes {
  .dc-u-z-#{$index} {
    z-index: #{$index} !important;
  }
}
